import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import AiEditIcon from '../../images/svg/AiEditIcon'
import AiInfoIcon from '../../images/svg/AiInfoIcon'
import { getCurrenntWidgets, getSearchParams } from '../../util/functions'
import { UpdateSubBoardWidgets } from '../../store/actions/SubBoardWidgetAction'
import httpService from '../../services/httpService'
import { customNotification } from '../../util/customNotification'

const AiModalStep2 = ({ setStep, setQues, result, onClose }) => {
    const { t } = useTranslation()
    const currentSubBoardWidget = useSelector(state => state.subBoardWidget)
    const currentSubBoardId = getSearchParams()?.subBoardId
    const { apiGroup } = useSelector(state => state.main)
    const [showSuccessMsg, setShowSuccessMsg] = useState(false);
    const [correctInfo, setCorrectInfo] = useState(true);

    const [widgetsArray, setWidgetsArray] = useState([])
    const dispatch = useDispatch()

    const currentWidgets = getCurrenntWidgets(currentSubBoardWidget, currentSubBoardId, apiGroup)

    useEffect(() => {
        const currSubWidgets = currentSubBoardWidget.dashboardWidget.find(item => item._id === currentSubBoardId).widgets
        if (currSubWidgets.length) {
            setWidgetsArray(getCurrenntWidgets(currentSubBoardWidget, currentSubBoardId, apiGroup))
        }
    }, [apiGroup, currentSubBoardId, currentSubBoardWidget])

    const handleClick = () => {
        setQues(result.search)
        setStep(1)
    }

    const getCurrentSubName = useMemo(() => {
        return currentSubBoardWidget.dashboardWidget.find((item) => item._id === currentSubBoardId).name
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSubBoardId])

    const updateWidgetArray = () => {
        const ActionType = 'widget-add'
        const oldWidgetArrayLength = currentWidgets?.length
        const payload = {
            type: 'PreConfigured',
            id: '647600c09e2e3ef9805e5043',
            widget: '647600c09e2e3ef9805e5043',
            name: result.search,
            x: (oldWidgetArrayLength * 4) % 12,
            y: 0,
            width: 4,
            height: 4.3,
            minHeight: 1,
            minWidth: 4,
            view: 'List',
            aiInfo: {
                aiQueryId: result.queryId
            }
        }
        widgetsArray.push(payload)
        dispatch(UpdateSubBoardWidgets(widgetsArray, apiGroup, currentSubBoardId, ActionType))
    }

    const addWidgetToSubBoard = () => {
        updateWidgetArray()
        onClose()
    }

    const handleFeedback = async (val) => {
        await httpService.post(`/ai/${apiGroup}/${result.queryId}/feedback`, { feedback: val }).then(() => {
            setShowSuccessMsg(true)
            setCorrectInfo(val)
        })
            .catch(() => {
                customNotification('error', 'Error sending feedback')
            })
    }

    return (
        <div className='ai-body'>
            {correctInfo &&
                <div className="ai-result-page">
                    <div className="res-ques"><div className="ques-title">
                        {result.search}</div>
                        <div className="edit" onClick={() => handleClick()}><AiEditIcon /></div>
                    </div>
                    <div className="res-div-wrap">
                        {result.result !== '' ?
                            <div dangerouslySetInnerHTML={{ __html: result.result }} />
                            : <h3>{t('No data found')}</h3>
                        }
                    </div>
                    <div className="add-to-board">
                        <button disabled={result.result === ''} className='btn-primary-new' onClick={() => addWidgetToSubBoard()}>+ {t('Add to')} {getCurrentSubName}</button>
                    </div>
                </div>}

            {showSuccessMsg ?
                <div className='feedback-msg-wrap'>
                    <div className="feedback-msg">
                        "{t('Thank you for your feedback')}"
                    </div>
                    {!correctInfo &&
                        <>
                            <div className='ai-border'>
                            </div>
                            <button onClick={() => setStep(1)} className='btn-primary-new'>{t('Ask another question')}</button>
                            {/* <div className="feedback-desc">
                                {t('the more you will ask the question the more we will be able to improve our performance')}
                            </div> */}
                        </>
                    }
                </div>
                :
                <div className='feedback-wrap'>
                    <div className='feedback-title'>
                        <AiInfoIcon /> {t('Is the information correct')}
                    </div>
                    <div className="btn-wrap">
                        <button className='btn-secondary-outline' onClick={() => handleFeedback(true)}>{t('Yes')}</button>
                        <button className='btn-secondary-outline' onClick={() => handleFeedback(false)}>{t('No')}</button>
                    </div>
                </div>
            }

            <hr />
            <div className="ai-note">
                <span>{t('NOTE')}</span> {t('Currently its in the beta version The results might be wrong')}
            </div>
        </div>
    )
}

export default AiModalStep2