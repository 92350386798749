import nProgress from 'nprogress'
import http from '../../services/httpService'
import { customNotification } from '../../util/customNotification'
import * as Types from './type'

//public board sharing
export const CreatePublicBoard = (widget, pages, id, lang) => dispatch => {
  nProgress.start()
  dispatch(RemovePublicBoardId())

  const payload = {
    group: id,
    widgets: widget,
    pages,
    lang,
  }
  http
    .post(`/group-account/${id}/public/share-board`, payload)
    .then(response => {
      dispatch({
        type: Types.SET_PUBLIC_BOARD_ID,
        payload: response.data?.boardId,
      })
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => nProgress.done())
}

export const RemovePublicBoardId = () => dispatch => {
  dispatch({
    type: Types.REMOVE_PUBLIC_BOARD_ID,
  })
}

export const SendPublicBoardLinkViaEmail = (emails, link, id) => dispatch => {
  nProgress.start()

  const payload = {
    recipients: emails,
    link: link,
  }
  http
    .post(`/public-share/url/send-email?groupId=${id}`, payload)
    .then(response => {
      if (response.data.sent === true) {
        customNotification('success', 'Link sent successfully')
      }
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => nProgress.done())
}

// add public board id to store
export const StorePublicBoardId = id => dispatch => {
  dispatch({
    type: Types.SET_PUBLIC_BOARD_ID,
    payload: id,
  })
}

// Fetch public board data
export const FetchPublicBoardData = id => dispatch => {
  nProgress.start()
  http
    .get(`/public-share/board/${id}`)
    .then(response => {
      dispatch({
        type: Types.FETCH_PUBLIC_BOARD_DATA,
        payload: response.data,
      })
      localStorage.setItem('publicGroupId', response.data?.group?._id)
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => nProgress.done())
}
