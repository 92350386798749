import * as Types from '../actions/type'

const cashflowReducer = (state = [], action) => {
  switch (action.type) {
    case Types.LOAD_CASHFLOW:
      return action.payload.cashflow
    default:
      return state
  }
}

export default cashflowReducer