import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'antd'

const ConsolidationModal = props => {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const deleteItem = () => {
    setLoading(true)
    props.deleteApi()
    setLoading(false)
    props.onHide()
  }

  const updateConsolidation = () => {
    setLoading(true)
    props.updateApi()
    setLoading(false)
    props.onHide()
  }

  const confirmClear = () => {
    setLoading(true)
    props.clear()
    setLoading(false)
    props.onHide()
  }
  return (
    <Modal
      visible={props.show}
      onCancel={props.onHide}
      className='create-new-board-modal'
      centered={true}
      footer={null}
      title={props.type === 'confirm' ? t('Confirm') : props.type === 'clear'
        ? t('Confirm')
        : t('Remove')}
    >
      <div className='cnbm-body'>
        {props.type === 'confirm' ? (
          <div>{t('Are you sure')}</div>
        ) : props.type === 'clear' ? (
          <div>{t('Are you sure')}</div>
        ) : (
          <div>
            {t('Remove')}
            {props.title}
            {t('from list')}
          </div>
        )}
      </div>

      <div className='cnbm-footer mt-4'>
        {props.type === 'confirm' ? (
          <button onClick={updateConsolidation} className='btn-primary-new'>
            {loading ? (
              <span className='btn-loading' />
            ) : (
              <span>{t('Yes')}</span>
            )}
          </button>
        ) : props.type === 'clear' ? (
          <button onClick={confirmClear} className='btn-primary-new'>
            {loading ? (
              <span className='btn-loading' />
            ) : (
              <span>{t('Yes')}</span>
            )}
          </button>
        ) : (
          <button onClick={deleteItem} className='btn-primary-new'>
            {loading ? (
              <span className='btn-loading' />
            ) : (
              <span>{t('Delete')}</span>
            )}
          </button>
        )}

        <button onClick={props.onHide} className='btn-secondary ml-3'>
          {t('Cancel')}
        </button>
      </div>
    </Modal>
  )
}

export default ConsolidationModal
