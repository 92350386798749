import * as React from 'react'

const TripleDotIcon = props => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 6.667c-.734 0-1.333.6-1.333 1.333S7.266 9.333 8 9.333 9.333 8.733 9.333 8 8.733 6.667 8 6.667Zm0-4c-.734 0-1.333.6-1.333 1.333S7.266 5.333 8 5.333 9.333 4.733 9.333 4 8.733 2.667 8 2.667Zm0 8c-.734 0-1.333.6-1.333 1.333s.6 1.333 1.333 1.333 1.333-.6 1.333-1.333-.6-1.333-1.333-1.333Z"
      fill="#72767E"
    />
  </svg>
)

export default TripleDotIcon