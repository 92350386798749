import * as Types from '../actions/type'

const initialState = []

const consolidationReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.UPDATE_CONSOLIDATION:
     return state = action.payload
    default:
      return state
  }
}

export default consolidationReducer
