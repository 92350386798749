import { Popconfirm, Tooltip } from 'antd'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SearchAccount from '../components/settings/account/SearchAccount'
import DropdownIcon from '../images/dropdownBoardIcon.svg'
import { ApiGroupAction } from '../store/actions/mainAction'
import { getFirstLetterFromName } from '../util/functions'
// import BoardSettingsIcon from '../images/boardSettings.svg'
import { useTranslation } from 'react-i18next'
import ConsolidationModal from '../components/settings/consolidation/ConsolidationModal'
import AddPageIcon from '../images/svg/addpage'
import ArrowDownIcon from '../images/svg/arrrow-down'
import TripleDotIcon from '../images/svg/tripleDot'
import CreateNewDashboardModal from './dashboard/createNewBoard'
// import useOutsideClick from '../util/useOutsideClick'
import { InfoCircleOutlined } from '@ant-design/icons'
import DeleteIcon from '../images/svg/consolidation/delete'
import EditIcon from '../images/svg/edit'
import http from '../services/httpService'
import { fetchAllSubBoardWidgetAction } from '../store/actions/SubBoardWidgetAction'
import { LoadApiGroup } from '../store/actions/apiGroupAction'
import * as Types from '../store/actions/type'
import { customNotification } from '../util/customNotification'

const BoardDropdown = React.memo(() => {
  const { t } = useTranslation()
  const ref = useRef()

  const groupAccounts = useSelector(state => state.groupAccount)
  const apiAccounts = useSelector(state => state.apiAccount)
  const apiGroup = useSelector(state => state.main.apiGroup)
  const currentGroup = useMemo(() => (groupAccounts?.filter(group => group._id === apiGroup)), [groupAccounts, apiGroup])
  const [showBoards, setShowBoards] = useState(false)
  const [showCreateBoardModal, setShowCreateBoardModal] = useState(false)
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [preventShowBoard, setPreventShowBoard] = useState(false)
  const [openCollapse, setOpenCollapse] = useState({})
  const [settingsId, setSettingsId] = useState('')
  const [searchField, setSearchField] = useState('')
  const dispatch = useDispatch()
  const wrapperRef = useRef(null)

  // useEffect(() => { }, [groupAccounts])

  // useOutsideClick(ref, () => {
  //   // if (setPreventShowBoard) {
  //   //   setPreventShowBoard(false)
  //   // }
  //   if (showBoards && !showCreateBoardModal && !showUpdateModal && !showDeleteModal) setShowBoards(false)
  // })

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        (document.querySelector('.pop-conf-no-pad')
          ? document.querySelector('.pop-conf-no-pad')?.classList.contains('ant-popover-hidden')
          : true) &&
        !document.querySelector('.ant-modal-mask')
      ) {
        setShowBoards(false)
        setSearchField('')
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  const handleBoardChange = id => {
    dispatch(ApiGroupAction(id))
    dispatch(fetchAllSubBoardWidgetAction(id))
    setSearchField('')
    window.localStorage.setItem('apiGroupName', id)
    window.localStorage.removeItem('subBoardId')
    if (!preventShowBoard) {
      setShowBoards(false)
    }
    setPreventShowBoard(false)
  }


  const handleUpdateModal = id => {
    setShowUpdateModal(true)
    setSettingsId(id)
  }

  const handleDeleteModal = id => {
    setShowDeleteModal(true)
    setSettingsId(id)
  }

  const filtereGroupdAccounts = useMemo(() => {
    return groupAccounts?.filter(group => {
      return group.name?.toLowerCase().startsWith(searchField.toLowerCase())
    })
  }, [searchField, groupAccounts])

  const handleCollapse = groupId => {
    openCollapse[groupId] = !openCollapse[groupId]
    setOpenCollapse({ ...openCollapse })
    // setPreventShowBoard(true)
  }

  const deleteGroup = id => {
    // setShowCoolLoader(true)
    http
      .delete(`/group-account/${id}`)
      .then(response => {
        dispatch(LoadApiGroup())
        if (response.status === 200 && response.data.isDeleted === true) {
          customNotification('success', t('API group deleted'))
        }
        localStorage.removeItem('apiGroupName')

        if (groupAccounts.length !== 0) {
          dispatch({
            type: Types.APIGROUP_CHANGE,
            payload: groupAccounts[0]._id,
          })
        }

        if (groupAccounts.length === 0) {
          dispatch({
            type: Types.LAST_APIGROUP_DELETE,
          })
        }
        // setShowCoolLoader(false)
      })
      .catch(error => {
        customNotification('error', t('Failed to delete API group'))
        // setShowCoolLoader(false)
      })
  }

  return (
    <div className='board-dropdwon mr-3'>
      <button className='btn-secondary-outline' onClick={() => setShowBoards(true)}>
        {groupAccounts?.length > 0 ? (
          <div className='board-name'>
            <span className='board-img mr-2'>
              <span className='initials'>{getFirstLetterFromName(currentGroup[0]?.name)}</span>
            </span>
            <span style={{ whiteSpace: 'nowrap' }}>{currentGroup[0]?.name}</span>
          </div>
        ) : (
          <div className='text-center' ref={ref}>
            {t('Add Board')}
          </div>
        )}
        <img src={DropdownIcon} alt='' />
      </button>
      {showBoards ? (
        <>
          <div className='board-dropdown-content mt-2' ref={wrapperRef}>
            <div className='top-bar-heading-section'>
              <span className='top-bar-group-title'>{t('All Companies')}</span>
              <button className='btn-outline-light' onClick={() => setShowCreateBoardModal(true)}>
                <AddPageIcon className='mr-2 add-page-icon' />
                <span className='ml-2'>{t('Add Company')}</span>
              </button>
            </div>
            <div className='bdc-add-page'>
              <CreateNewDashboardModal show={showCreateBoardModal} onHide={() => setShowCreateBoardModal(false)} />
            </div>

            {/* board list starts */}
            <div className='bdc-board-list-wrap'>
              <div className='board-search-btn pb-2'>
                <SearchAccount search='Search' setFunc={setSearchField} />
              </div>
              {filtereGroupdAccounts?.length > 0 ? (
                filtereGroupdAccounts.map(board => {
                  return (
                    <div
                      ref={ref}
                      className='bdc-board-list '
                      key={board._id}
                    >
                      <div className='bdc-board-list-item-name'>
                        <div
                          className='d-flex align-items-center bdc-hover'
                          title={board.name}
                          onClick={() => handleBoardChange(board._id)}>
                          <span className='board-img mr-2'>
                            <span className='initials'>{getFirstLetterFromName(board.name)}</span>
                          </span>
                          <span className='board-name' style={{ whiteSpace: 'nowrap' }}>
                            {board.name}
                          </span>
                        </div>
                        <div className='pl-5 d-flex align-items-center'>
                          {board.apis.length ? null : (
                            <Tooltip title={t('Connect API to the board to view reports')}>
                              <InfoCircleOutlined style={{ color: '#f76552' }} />
                            </Tooltip>
                          )}
                          <Popconfirm
                            overlayClassName='pop-conf-no-pad'
                            placement='bottomLeft'
                            icon={null}
                            onConfirm={() => handleDeleteModal(board._id)}
                            onCancel={() => handleUpdateModal(board._id)}
                            title={null}
                            okText={
                              <>
                                <DeleteIcon style={{ marginTop: '-2px' }} /> {t('Delete Board')}
                              </>
                            }
                            cancelText={
                              <>
                                <EditIcon /> {t('Edit Board')}
                              </>
                            }
                            cancelButtonProps={{
                              className: 'btn-left-bordered bg-white w-100 border-0 mx-0',
                            }}
                            okButtonProps={{
                              className: 'btn-left-bordered bg-white w-100 border-0 mx-0',
                            }}>
                            <TripleDotIcon style={{ cursor: 'pointer', margin: '0 5px' }} onClick={() => setPreventShowBoard(true)} />
                          </Popconfirm>
                          <div onClick={() => handleCollapse(board._id)} style={{ cursor: 'pointer', transform: openCollapse[board._id] ? 'rotate(0deg)' : 'rotate(-90deg)', transition: 'ease-in-out .3s all' }}>
                            <ArrowDownIcon />
                          </div>
                        </div>
                      </div>
                      {openCollapse[board._id] ? (
                        <>

                          <div className='' key={board._id}>
                            {board.apis.length
                              // eslint-disable-next-line array-callback-return
                              ? board.apis.map(apiId => {
                                const name = apiAccounts.find(apiAccount => apiAccount._id === apiId)?.name
                                if (name !== undefined) {
                                  return (
                                    <div
                                      className='bdc-board-list-item-name justify-content-start ml-4'
                                      title={name}
                                      key={apiId}>
                                      <span className='child-board-img mr-2'>
                                        <span className='initials'>{getFirstLetterFromName(name)}</span>
                                      </span>
                                      <span className='child-board-name' style={{ whiteSpace: 'nowrap' }}>
                                        {name}
                                      </span>
                                    </div>
                                  )
                                }
                              })
                              : null}
                          </div>
                        </>
                      ) : null}
                    </div>
                  )
                })
              ) : (
                <div className='text-center' ref={ref}>
                  {t('No Boards')}
                </div>
              )}
            </div>
          </div>
          <CreateNewDashboardModal
            show={showUpdateModal}
            onHide={() => setShowUpdateModal(false)}
            editBoard={true}
            id={settingsId}
          />
          <ConsolidationModal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
            updateApi={() => deleteGroup(settingsId)}
            type='confirm'
          />
        </>
      ) : null}
    </div>
  )
})

export default BoardDropdown
