import http from '../../services/httpService'
import jwtDecode from 'jwt-decode'
// import axios from 'axios'
// import loginWithJwt from '../../services/authService'
import * as Types from '../../store/actions/type'
import { loginWithJwt, updateCurrentUser } from '../../services/authService'
import axios from 'axios'
import { customNotification } from '../../util/customNotification'
import LogRocket from 'logrocket'

//Register
export const register = (user, history, id) => dispatch => {
  const payload = id ? { ...user, integrationPartnerId: id } : { ...user }
  http
    .post('/auth/signup', payload)
    .then(response => {
      if (response.data) {
        customNotification('success', 'Verification Email has been sent to your email address, Please verify and login')
      }
      let token = response.data.jwtToken
      http.setTokenToLocalStorage(response)
      http.setAuthToken(token)
      let decode = jwtDecode(token)
      dispatch({
        type: Types.SET_USER,
        payload: {
          user: {
            ...decode,
            firstName: response.data.firstName,
            email: response.data.email,
          },
        },
      })
      history.push(`/register-notification?email=${user.email}`)
    })
    .catch(error => {
      if (error?.response?.data?.translateKey === 'USER_ALREADY_EXISTS') {
        customNotification('error', 'User already exists')
      }
      dispatch({
        type: Types.USERS_ERROR,
        payload: {
          error: error.response.data,
        },
      })
    })
}

//Login
export const login = (user, history, id) => dispatch => {
  const payload = id ? { ...user, integrationPartnerId: id } : { ...user }
  http
    .post('/auth/login', payload)
    .then(function (response) {
      let {
        jwtToken,
        firstName,
        lastName,
        email,
        companyName,
        phoneNumber,
        isVerified,
        role,
        menu,
        isFirstLoggedIn,
      } = response?.data

      if (response.data.id) {
        customNotification('success', 'Login Successful')
      }
      if (response.data.warning) {
        customNotification('info', response.data.warning.message)
      }
      dispatch({
        type: Types.SHOW_WELCOME_VIDEO,
        payload: isFirstLoggedIn ? isFirstLoggedIn : false,
      })
      http.setTokenToLocalStorage(response)
      loginWithJwt(jwtToken)
      updateCurrentUser(response.data)
      http.setAuthToken(jwtToken)
      let decode = jwtDecode(jwtToken)

      // START logrocket
      LogRocket.identify(response.data.id, {
        name: firstName + ' ' + lastName,
        email,
        // Add your own custom user variables here, ie:
        phoneNumber,
        role,
        subscriptionType: '__NOT__AVAILABLE__FOR__NOW__',
        isFirstLoggedIn,
      })
      // END logrocket

      axios.get(`/profile/me`).then(responseTwo => {
        let avatar = responseTwo.data?.avatar
        localStorage.setItem('avatar', avatar)
        dispatch({
          type: Types.SET_USER,
          payload: {
            user: {
              ...decode,
              firstName,
              lastName,
              email,
              avatar,
              companyName,
              phoneNumber,
              isVerified,
            },
          },
        })

        dispatch({
          type: Types.SET_MENU,
          payload: {
            accessControl: {
              role,
              menu,
            },
          },
        })

        if (id) {
          history.push('/api-settings/api-accounts')
        } else if (history.location.search) {
          const queryParamObj = new URLSearchParams(history.location.search)

          if (queryParamObj.get('redirect')) {
            window.location.assign(queryParamObj.get('redirect'))
          }
        } else {
          history.push('/')
        }
      })
    })
    .catch(function (error) {
      console.log(error?.response?.data)
      if (error?.response?.data?.message === 'Could not locate resource for the tripletex integration') {
        customNotification('error', 'Could not locate resource for the tripletex integration')
      } else if (error?.response?.data?.translateKey === 'EMAIL_IS_NOT_VERIFIED') {
        customNotification('error', 'Email is not verified, Please verify your email and try again')
        history.push('/send-verification')
      } else {
        customNotification('error', 'Incorrect Credentials')
      }
      dispatch({
        type: Types.USERS_ERROR,
        payload: {
          error: error?.response ? error.response.data : error,
        },
      })
    })
}

//Logout
export const logout = history => dispatch => {
  let payload = {
    refreshToken: localStorage.getItem('refresh_token'),
  }

  http.post('/auth/logout', payload).then(response => {
    if (response.data?.isLoggedOut === true) {
      customNotification('success', 'Logout Successful')
      localStorage.clear()
      dispatch({
        type: Types.LOGOUT,
      })

      history.push('/login')
      return {
        type: Types.SET_USER,
        payload: {
          user: {},
        },
      }
    }
  })
}

//Forgot password
export const forgotPassword = (email, history) => dispatch => {
  http
    .post('/auth/password/request-reset', email)
    .then(function (response) {
      if (response.status === 200) {
        customNotification('success', 'Password Reset link sent to your email')
        history.push('/login')
      }
    })

    .catch(function (error) {
      if (
        error?.response?.data?.message === 'User does not exist' ||
        error?.response?.data?.message === 'User is not found'
      ) {
        customNotification('error', 'User does not exists')
      } else {
        console.log(error.response)
      }
    })
}

//Reset password
export const resetPassword = (user, history) => dispatch => {
  http
    .patch('/auth/password/reset', user)
    .then(function (response) {
      console.log(response)
      if (response?.data?.isReset === true) {
        customNotification('success', 'Password Reset Successful, Login with your new password')
        history.push('/')
      }
      if (response?.data?.message[0] === 'password length must be at least 8 characters long') {
        customNotification('error', 'password length must be at least 8 characters long')
      }
    })

    .catch(function (error) {
      if (error?.response?.data?.message === 'Invalid or expired password reset token') {
        customNotification('error', 'Password reset token expired, Please submit your email again')
        history.push('/password/forgot')
      }
    })
}

//Account Verify
export const accountVerify = (payload, history) => dispatch => {
  http
    .patch('/auth/account/verify', payload)
    .then(function (response) {
      if (response.data === true) {
        customNotification('success', 'Account Verified, Please Login')
        history.push('/login')
      }
    })

    .catch(function (error) {
      console.log(error.response)
      if (error?.response?.data?.message === 'Invalid or expired email verification token') {
        customNotification('error', 'Email verification token expired, Please submit your email again')
        history.push('/send-verification')
      }
    })
}

//Verification Email
export const verifyEmail = (email, history) => dispatch => {
  http
    .post('/auth/account/send/verification-email', email)
    .then(function (response) {
      console.log(response)
      if (typeof response?.data === 'string') {
        customNotification('success', 'Email verify link sent to your email')
        history.push('/login')
      } else if (response?.data?.message[0] === 'email must be a valid email') {
        customNotification('error', 'Email Not found')
      }
    })

    .catch(function (error) {
      if (error?.response?.data?.message === 'User email account is already verified') {
        customNotification('error', 'User email account is already verified')
      } else {
        console.log(error)
      }
    })
}
