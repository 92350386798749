import * as Types from '../actions/type'

const sync = {
  syncProgress: 0,
  showProgressBar: false,
  progressData: {},
}

const syncBoardReducer = (state = sync, action) => {
  switch (action.type) {
    case Types.BOARD_SYNC:
      return {
        ...state,
        syncProgress: action.payload,
      }

    case Types.SHOW_PROGRESS:
      return {
        ...state,
        showProgressBar: true,
      }

    case Types.HIDE_PROGRESS:
      return {
        ...state,
        showProgressBar: false,
      }

    case Types.SYNC_BOARD_PROGRESS:
      return {
        ...state,
        progressData: action.payload,
      }

    default:
      return state
  }
}

export default syncBoardReducer
