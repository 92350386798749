import * as React from 'react'

function AddPageIcon({ size = 20, ...props }) {
  return (
    <svg width={size} height={size} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M18.297 9.297h-7.594V1.703a.703.703 0 10-1.406 0v7.594H1.703a.703.703 0 100 1.406h7.594v7.594a.703.703 0 001.406 0v-7.594h7.594a.703.703 0 000-1.406z'
        fill='#2D2D2D'
        stroke='#2D2D2D'
      />
    </svg>
  )
}

export default AddPageIcon
