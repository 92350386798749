import { Modal } from 'antd'
import Pusher from 'pusher-js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'
import { getAllApiAccount } from '../store/actions/apiAccountAction'
import { DeleteApiGroup, LoadApiGroup } from '../store/actions/apiGroupAction'
import { LoadCashflow } from '../store/actions/cashflowAction'
import { ApiGroupAction, ApiGroupDelete, ShowWelcomeVideo } from '../store/actions/mainAction'
import { LoadProfit } from '../store/actions/profitAction'
import { fetchAllSubBoardWidgetAction } from '../store/actions/SubBoardWidgetAction'
import { hideProgress, progressData, showProgress, syncBoardProgress } from '../store/actions/syncBoardAction'
import { filteredByModules } from '../util/functions'
import BoardDropdown from './BoardDropdown'
import DeleteBoardModal from './dashboard/deleteBoardModal'
import TopbarMenu from './topbarMenu'

const Topbar = props => {
  const { t } = useTranslation()
  // const { apiGroup } = window.localStorage.getItem('apiGroupName') || props.main
  const APP_KEY = 'f909139646ddf17d038e'
  const APP_CLUSTER = 'ap2'
  const CHANNEL = 'DataSource'
  const [syncDatas, setSyncDatas] = useState([])
  const [showDelete, setShowDelete] = useState(false)
  const { menu } = useSelector(state => state.auth.accessControl)
  const { showWelcomeVideo } = useSelector(state => state.main)
  const dispatch = useDispatch();

  // GroupAccount Permissions
  const groupAccountPermission = filteredByModules(menu, 'GroupAccount')
  const gaPermissions = groupAccountPermission?.permissions

  //ApiAccount Permissions
  const apiAccountPermission = filteredByModules(menu, 'ApiAccount')

  useEffect(() => {
    //fetch all groups
    props.LoadApiGroup()
    if (apiAccountPermission !== undefined) {
      props.getAllApiAccount()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const syncData = () => {
    props.syncBoardProgress(props.main.apiGroup)
    props.showProgress()

    const pusher = new Pusher(APP_KEY, {
      cluster: APP_CLUSTER,
    })

    const channel = pusher.subscribe(CHANNEL)
    channel.bind('syncing_board', data => {
      console.log(data)
      setSyncDatas(data)
    })
  }

  useEffect(() => {
    if (syncDatas?.progress) {
      props.progressData(syncDatas.progress)
    }
    if (syncDatas?.isSyncDone === true) {
      props.hideProgress()
      props.LoadCashflow()
      props.LoadProfit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncDatas])

  const hideVideo = () => {
    dispatch(ShowWelcomeVideo(false))
  }

  return (
    <>
      {/* START top bar  */}
      <div className='top-bar'>
        <div className='top-bar-contents'>
          <div className='tbc-left'>
            {/* <WidgetsDropdown /> */}
            {gaPermissions?.includes('View') || gaPermissions?.includes('*') ? <BoardDropdown /> : null}
            <DeleteBoardModal show={showDelete} onHide={() => setShowDelete(false)} id={props.main.apiGroup} />
          </div>
          <div className='tbc-right'>
            <div className='user-controls'>
              <TopbarMenu syncData={syncData} />
            </div>
          </div>
        </div>
      </div>

      <div className='divider divider--full mb-3' style={{ marginTop: '0px' }} />
      <Modal className='widget-modal' title={t('Video')} visible={showWelcomeVideo} onCancel={() => hideVideo()}
        footer={null}
      >
        <video src="https://reportify.no/wp-content/uploads/2022/03/Login.mp4" autoPlay={false} width="100%" height="400" controls></video>
      </Modal>
    </>
  )
}

const mapStateToProps = state => {
  return {
    companies: state.groupAccount,
    main: state.main,
  }
}

export default connect(mapStateToProps, {
  DeleteApiGroup,
  LoadApiGroup,
  LoadCashflow,
  LoadProfit,
  ApiGroupAction,
  getAllApiAccount,
  syncBoardProgress,
  showProgress,
  hideProgress,
  progressData,
  ApiGroupDelete,
  fetchAllSubBoardWidgetAction,
})(Topbar)
