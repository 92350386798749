import React from 'react'
import { useTranslation } from 'react-i18next'

export default function CoolLoader(props) {
  const { t } = useTranslation()
  return (
    <div className='cool-loading'>
      <div className='btn btn-primary'>
        <span className='btn-loading' />
        <span className='ml-3'>{t(props.message)}...</span>
      </div>
    </div>
  )
}
