import React, { useState, useEffect } from 'react'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { Radio } from 'antd'
// import FlagNor from '../../images/svg/flag-nor'
// import FlagEn from '../../images/svg/flag-en'
import { LoadCashflow } from '../../store/actions/cashflowAction'
import { LoadProfit } from '../../store/actions/profitAction'
import { useDispatch, useSelector } from 'react-redux'
import { UpdateLocale } from '../../store/actions/mainAction'

const Language = props => {
  const { apiGroup } = useSelector(state => state.main)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const initialLanguage = () => window.localStorage.getItem('i18nextLng') || 'nor'
  const [activeLang, updateActiveLang] = useState('')

  useEffect(() => {
    updateActiveLang(initialLanguage())
  }, [])

  useEffect(() => {
    window.localStorage.setItem('i18nextLng', activeLang)
  }, [activeLang])

  const onChange = e => {
    const lang = e.target.value

    dispatch(UpdateLocale(lang))
    i18n.changeLanguage(lang)
    updateActiveLang(lang)
    if (apiGroup !== '') {
      dispatch(LoadCashflow())
      dispatch(LoadProfit())
    }
  }

  return (
    <div id={'mw-lang-switch'} {...props}>
      <Radio.Group onChange={onChange} value={activeLang}>
        <Radio value={'en'} className='flex'>
          {'ENG'}
        </Radio>
        <Radio value={'nor'}>{'NOR'}</Radio>
      </Radio.Group>
      {/* {activeLang === 'nor' ? (
        <button className={'btn-lang active'} onClick={() => handleClick('en')}>
          <span className='text'>NOR</span>
          <span className='lang-icon'>
            <FlagNor />
          </span>
        </button>
      ) : (
        <button className={'btn-lang active'} onClick={() => handleClick('nor')}>
          <span className='text'>EN</span>
          <span className='lang-icon'>
            <FlagEn />
          </span>
        </button>
      )} */}
    </div>
  )
}

export default Language
