import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import Layout from '../../layout/Layout'
import auth from '../../services/authService'
import { filteredRoutes } from '../../util/functions'
import './nprogress.css'

const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
  const menu = useSelector(state => state.auth?.accessControl?.menu)
  const allowedRoutes = filteredRoutes(menu)

  return (
    <Route
      {...rest}
      render={props => {
        if (!auth.getCurrentUser())
          return (
            <Redirect
              to={{
                pathname: `/login`,
                search: `?redirect=${window.location.href}`,
                state: { from: props.location },
              }}
            />
          )
        // check if route is restricted by role
        if (allowedRoutes?.includes(path) === false) {
          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: '/dashboard' }} />
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }}
    />
  )
}

export default ProtectedRoute
