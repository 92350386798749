import React, { lazy } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import ProtectedRoute from './components/common/protectedRoute'

const PersonalSettings = lazy(() => import('./pages/ProfileSettings/PersonalSettings'))
const Dashboard = lazy(() => import('./pages/dashboard'))
const ApiSettings = lazy(() => import('./pages/ApiSettings'))
const BalanceSheet = lazy(() => import('./pages/balanceSheet'))
const Cashflow = lazy(() => import('./pages/cashflow'))
const ConfirmUserInvitePage = lazy(() => import('./pages/confirmUserInvite'))
const customer = lazy(() => import('./pages/customer'))
const customerDetails = lazy(() => import('./pages/customerDetails'))
const Group = lazy(() => import('./pages/group'))
const Login = lazy(() => import('./pages/login'))
const SignupVerifyNotification = lazy(() => import('./pages/SignupVerifyNotification'))
const forgot = lazy(() => import('./pages/password/forgot'))
const Reset = lazy(() => import('./pages/password/reset'))
const Profit = lazy(() => import('./pages/profit'))
const PublicDashboard = lazy(() => import('./pages/public/publicDashboard'))
const PublicCashflow = lazy(() => import('./pages/public/publicCashflow'))
const PublicProfitloss = lazy(() => import('./pages/public/publicProfitloss'))
const PublicBalance = lazy(() => import('./pages/public/publicBalance'))
const Register = lazy(() => import('./pages/register'))
const SuperAdmin = lazy(() => import('./pages/super-admin/dashboard'))
const FreeCustomersList = lazy(() => import('./pages/super-admin/freeCustomersList'))
const AdminRegistration = lazy(() => import('./pages/super-admin/registration'))
const VerificationEmail = lazy(() => import('./pages/verificationEmail'))
const VerifyAccount = lazy(() => import('./pages/verifyAccount'))
const TripletexIntegration = lazy(() => import('./pages/redirects/TripletexIntegration'))
const TestTripletex = lazy(() => import('./pages/redirects/TestTripletex'))

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/tripletex-test' component={TestTripletex} />
        <Route path='/login' component={Login} />
        <Route path='/register-notification' component={SignupVerifyNotification} />
        <Route path='/register' component={Register} />
        <Route path='/admin/dashboard' component={SuperAdmin} />
        <Route path='/password/reset' component={Reset} />
        <Route path='/password/forgot' component={forgot} />
        <Route path='/verify-account' component={VerifyAccount} />
        <Route path='/send-verification' component={VerificationEmail} />
        <Route path='/confirm-invitation' component={ConfirmUserInvitePage} />
        <Route path='/admin/free-customers' component={FreeCustomersList} />
        <Route path='/admin/registration' component={AdminRegistration} />
        <Route path='/public/board/:id' component={PublicDashboard} />
        <Route path='/public/cashflow/:id' component={PublicCashflow} />
        <Route path='/public/profitloss/:id' component={PublicProfitloss} />
        <Route path='/public/balancesheet/:id' component={PublicBalance} />
        <Route path='/redirect/' component={TripletexIntegration} />
        <ProtectedRoute path='/' exact component={Dashboard} />
        <ProtectedRoute path='/profile-settings' component={PersonalSettings} />
        <ProtectedRoute path='/api-settings' component={ApiSettings} />
        <ProtectedRoute path='/profit' component={Profit} />
        <ProtectedRoute path='/dashboard' component={Dashboard} />
        <ProtectedRoute path='/cashflow' component={Cashflow} />
        <ProtectedRoute path='/balance-sheet' component={BalanceSheet} />
        <ProtectedRoute path='/customer/:id' component={customerDetails} />
        <ProtectedRoute path='/customer' component={customer} />
        <ProtectedRoute path='/group' component={Group} />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
