import React, { useEffect, useRef, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ArrowDownIcon from '../../images/svg/arrrow-down'
import { useDispatch, useSelector } from 'react-redux'
import CirclePlusIcon from '../../images/svg/circle-plus'
import DeleteIcon from '../../images/svg/delete'
import DeleteBoardIcon from '../../images/svg/deleteBoard'
import http from '../../services/httpService'
import { DeleteApiGroup, UpdateGroupAccount } from '../../store/actions/apiGroupAction'
import * as Types from '../../store/actions/type'
import { customNotification } from '../../util/customNotification'
import Modal from 'antd/lib/modal/Modal'
import AddPageIcon from '../../images/svg/addpage'
import SearchAccount from '../settings/account/SearchAccount'
import useOutsideClick from '../../util/useOutsideClick'

const CreateNewDashboardModal = React.memo(({ show, onHide, editBoard, id }) => {
  const { t } = useTranslation()
  const ref = useRef()

  const groupAccount = useSelector(state => state.groupAccount)
  const [showApiAccDd, setShowApiAccDd] = useState(false)
  // const [currentBoardName, setCurrentBoardName] = useState('')
  const [boardName, setBoardName] = useState('')
  const [selectedApiAccounts, setSelectedApiAccounts] = useState({})
  const [loading, setLoading] = useState(false)
  const apiAccounts = useSelector(state => state.apiAccount)
  const [searchField, setSearchField] = useState('')
  // const [showDelete, setShowDelete] = useState(false)
  const dispatch = useDispatch()
  const editBoardAcc = groupAccount?.filter(item => item._id === id)
  const currentBoard = editBoardAcc[0]

  useEffect(() => {
    if (editBoard && groupAccount.length > 0 && id) {
      const editBoardAcc = groupAccount?.filter(item => item._id === id)
      const currentBoard = editBoardAcc[0]
      // setCurrentBoardName(currentBoard?.name)
      setBoardName(currentBoard?.name)
      const obj = {}
      const currentApis = apiAccounts?.filter(item => currentBoard?.apis?.includes(item._id))
      const currentSelectedApis = currentApis?.map(item => (obj[item._id] = { _id: item._id, name: item.name }))
      setSelectedApiAccounts(obj)
    }
  }, [groupAccount, id])

  const filteredAccounts = apiAccounts?.filter(account => {
    return account.name?.toLowerCase().includes(searchField.toLowerCase())
  })

  const updateSelectedApiAccounts = account => {
    setSelectedApiAccounts(prevState => ({
      ...prevState,
      [account._id]: account,
    }))
  }

  const removeAccFromSelectedList = id => {
    setSelectedApiAccounts(prevState => {
      delete prevState[id]
      return { ...prevState }
    })
  }

  // const deleteBoard = () => {
  //   dispatch(DeleteApiGroup(id))
  //   onHide()
  //   setShowDelete(false)
  // }

  const updateBoard = () => {
    const payload = {
      name: boardName,
      apis: Object.keys(selectedApiAccounts),
    }
    dispatch(UpdateGroupAccount(id, payload))
    customNotification('success', 'Company board is updated')
    onHide()
  }

  useOutsideClick(ref, () => {
    if (showApiAccDd) setShowApiAccDd(false)
  })

  const createNewBoard = () => {
    setLoading(true)

    if (boardName && boardName.length) {
      http
        .post('/group-account', {
          name: boardName,
          apis: Object.keys(selectedApiAccounts),
        })
        .then(response => {
          dispatch({
            type: Types.ADD_GROUPACCOUNT,
            payload: {
              groupAccount: response.data,
            },
          })
          if (response.status === 201) {
            customNotification('success', 'New board Created')
          }
          dispatch({
            type: Types.APIGROUP_CHANGE,
            payload: response.data._id,
          })
          window.localStorage.setItem('apiGroupName', response.data._id)

          setLoading(false)
          onHide()
        })
        .catch(error => {
          customNotification('error', 'Failed')
          console.log(error)
        })
        .finally(() => {
          setLoading(false)
        })

      if (!Object.keys(selectedApiAccounts).length) {
        customNotification('warning', 'This board doesnt have any accounts connected')
      }
    } else {
      customNotification('warning', 'Board name is empty')
      setLoading(false)
    }
  }

  return (
    <Modal
      className='create-new-board-modal'
      title={t(editBoard ? 'Configure Board' : 'Create New Board')}
      visible={show}
      onCancel={onHide}
      footer={null}>
      <>
        <div className='create-new-board-modal-content'>
          <div className='cnbm-body'>
            <Form>
              <Form.Group controlId='boardTitle'>
                <Form.Control
                  type='text'
                  placeholder={t('Type board name')}
                  // value={boardName}
                  key={id}
                  defaultValue={editBoard ? currentBoard?.name : boardName}
                  onChange={e => setBoardName(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId='boardApiAccounts'>
                <Form.Label>{t('Check API from list')}</Form.Label>

                <div className='board-api-acc-dd-trigger' onClick={() => setShowApiAccDd(!showApiAccDd)}>
                  <Form.Control
                    type='text'
                    placeholder={t('Choose API from list')}
                    disabled
                    style={{ borderColor: showApiAccDd ? '#F76552' : '' }}
                  />
                  <span className='dropdown-icon' style={{ transform: showApiAccDd ? 'rotate(-180deg)' : '' }}>
                    <ArrowDownIcon />
                  </span>
                </div>

                {Object.keys(selectedApiAccounts).length ? (
                  <div className='selected-api-apiAccounts'>
                    {Object.values(selectedApiAccounts).map(acc => (
                      <div className='selected-api' key={acc._id}>
                        <span className='name'>{acc.name}</span>
                        <span className='remove-icon' onClick={() => removeAccFromSelectedList(acc._id)}>
                          <DeleteIcon />
                        </span>
                      </div>
                    ))}
                  </div>
                ) : (
                  ''
                )}

                {showApiAccDd ? (
                  <div className='board-api-acc-dd' ref={ref}>
                    {apiAccounts.length ? (
                      <div>
                        <div className='api-acc-search'>
                          <SearchAccount search='Search API' setFunc={setSearchField} />
                        </div>
                        {filteredAccounts.length ? (
                          filteredAccounts.map(account => (
                            <div
                              key={account._id}
                              data-api-id={account._id}
                              data-api-name={account.name}
                              className={
                                'board-api-account ' +
                                Object.values(selectedApiAccounts).map(acc =>
                                  acc._id === account._id ? ' selected ' : ' '
                                )
                              }
                              onClick={() =>
                                updateSelectedApiAccounts({
                                  _id: account._id,
                                  name: account.name,
                                })
                              }>
                              <span className='name'>{account.name}</span>
                              <div className='status-icon'>
                                <CirclePlusIcon />
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className='p-3 text-center'>{t('No Api Account Found')}</div>
                        )}
                      </div>
                    ) : (
                      <div className='mt-3 border border-primary py-2 px-3 rounded-lg'>{t('No api account')}</div>
                    )}
                  </div>
                ) : (
                  <span />
                )}
              </Form.Group>
            </Form>
          </div>
          <div className='cnbm-footer'>
            <button className='btn-secondary' onClick={onHide}>
              {t('Cancel')}
            </button>
            {editBoard ? (
              <button className='btn-outline-light' onClick={() => updateBoard()}>
                {t('Update')}
              </button>
            ) : (
              <button className='btn-outline-light' onClick={createNewBoard}>
                {loading ? (
                  <span className='btn-loading' />
                ) : (
                  <span>
                    <AddPageIcon className='mr-2 add-page-icon' />
                    <span>{t('Add Board')}</span>
                  </span>
                )}
              </button>
            )}
          </div>
        </div>

        {/* Delete button */}
        {/* {editBoard && (
          <div className="cnbm-delete-btn-wrap">
            <button className='btn-outline-light' onClick={() => setShowDelete(true)}>
              <DeleteBoardIcon className='mr-2 mb-1' />
              {t('Deleteboard')}</button>
          </div>)} */}
      </>
    </Modal>
  )
})

export default CreateNewDashboardModal
