import * as React from 'react'

const FolderIcon = props => (
  <svg width={16} height={16} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M13.6 3.333H7.59L6.395 2.195A.701.701 0 0 0 5.9 2H2.4C1.628 2 1 2.598 1 3.333v9.334C1 13.402 1.628 14 2.4 14h11.2c.772 0 1.4-.598 1.4-1.333v-8c0-.736-.628-1.334-1.4-1.334ZM2.4 12.667v-8h11.2l.002 8H2.4Z'
      fill='#343538'
    />
  </svg>
)

export default FolderIcon
