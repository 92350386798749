import React from 'react'
import { getFirstLetterFromName } from '../../util/functions'

const Avatar = props => {
  let userImg = props.user?.avatar || localStorage.getItem('avatar')
  let userName = props.user?.firstName || localStorage.getItem('firstName')

  return (
    <>
      {userImg && userImg !== 'undefined' ? (
        <img src={userImg} alt={userName} />
      ) : (
        <span className='initials'>{getFirstLetterFromName(userName)}</span>
      )}
    </>
  )
}

export default Avatar
