import * as Types from '../actions/type'

const init = {
  user: {
    firstName: localStorage.getItem('firstName') || '',
    lastName: localStorage.getItem('lastName') || '',
    email: localStorage.getItem('email') || '',
    phoneNumber: localStorage.getItem('phoneNumber') || '',
    companyName: localStorage.getItem('companyName') || '',
    avatar: localStorage.getItem('avatar') || '',
    isVerified: false,
  },
  accessControl: {
    role: [],
    menu: [],
  },
  isAuthenticated: false,
  error: {},
}

const authReducer = (state = init, action) => {
  switch (action.type) {
    case Types.SET_USER: {
      return {
        ...state,
        user: action.payload.user,
        error: {},
      }
    }

    case Types.SET_MENU: {
      return {
        ...state,
        accessControl: action.payload.accessControl,
        isAuthenticated: Object.keys(state.user).length !== 0,
      }
    }

    case Types.USERS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
      }
    }

    default:
      return state
  }
}

export default authReducer
