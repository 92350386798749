import * as React from 'react'

function ShareIcon(props) {
  return (
    <svg width={16} height={16} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M12 5.333a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM4 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM12 14.667a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM5.727 9.007l4.553 2.653M10.273 4.34 5.727 6.993'
        stroke='#F64831'
        strokeWidth={1.6}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default ShareIcon
