/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { Dropdown, Tooltip } from 'antd'
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useHistory, useLocation, withRouter } from 'react-router-dom'
import CloseArrow from '../images/close-arrow.svg'
import DeleteIcon from '../images/svg/consolidation/delete'
import Logo from '../images/svg/logo'
import {
  ApiDocumentationIcon,
  BalanceSheetIcon,
  BalanceSheetOnSelectIcon,
  BugReportIcon,
  CashFlowIcon,
  CashFlowOnSelectIcon,
  ConfigurationIcon,
  ConfigurationOnSelectIcon,
  DashboardIcon,
  DashboardOnSelectIcon,
  FeedbackIcon,
  HelpIcon,
  LanguageIcon,
  LogoutIcon,
  ProfileIcon,
  ProfitLossIcon,
  ProfitLossOnSelectIcon,
  SuperAdminPanelIcon,
  TutorialIcon,
  WhatsNewIcon,
} from '../images/svg/new-icons/icons'
import { logout } from '../store/actions/authAction'
import { filteredMenus } from '../util/functions'
import Avatar from './common/avatar'
// import EditIcon from '../images/svg/edit'
import { Form, Formik } from 'formik'
import { object, string } from 'yup'
import DownIcon from '../images/arrow-down.svg'
import RightIcon from '../images/arrow-right.svg'
import AddPageIcon from '../images/svg/addpage'
import FolderIcon from '../images/svg/folder'
import http from '../services/httpService'
import { fetchAllSubBoardWidgetAction } from '../store/actions/SubBoardWidgetAction'
import { ShowWelcomeVideo } from '../store/actions/mainAction'
import * as Types from '../store/actions/type'
import { customNotification } from '../util/customNotification'
import Language from './common/language'
import DashboardModal from './dashboard/createSubBoardModal'
import ConsolidationModal from './settings/consolidation/ConsolidationModal'
import EditIcon from '../images/svg/edit'

const CreateSubBoardSchema = object().shape({
  name: string().required('Sub-board name is required'),
})

const Sidebar = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const updateSubBoardState = {
    subBoardId: '',
    updateName: '',
  }

  // const [activeMenu, setActiveMenu] = useState(1)
  const { menu, role } = useSelector(state => state.auth.accessControl)
  const apiAccounts = useSelector(state => state.apiAccount)
  const apiGroup = useSelector(state => state.main.apiGroup)
  const { public_pages } = useSelector(state => state.public)
  const { public_board_id: id } = useSelector(state => state.public)
  const [showSidebar, setShowSidebar] = useState(true)
  const [showHelp, setShowHelp] = useState(false)
  const [showDropDown, setShowDropDown] = useState(false)
  const [showDropDownDashboard, setShowDropDownDashboard] = useState(false)
  const [openCollapse, setOpenCollapse] = useState(false)
  const [showSubBoardModal, setShowSubBoardModal] = useState(false)
  const allowedSideMenus = filteredMenus(menu)
  const [loading, setLoadingState] = useState(false)
  const { user } = useSelector(state => state.auth)
  const [subMenuName, setSubMenuName] = useState('')
  const [deleteSubMenuId, setDeleteSubMenuId] = useState('')
  const [updateSubBoardName, setUpdateSubBoardName] = useState(updateSubBoardState)
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showSettings, setShowSettings] = useState([])
  const [activeDashSubBoard, setActiveDashSubBoard] = useState('')
  const dashboardFolders = useSelector(state => state.subBoardWidget.dashboardWidget)
  const location = useLocation()
  const path = location.pathname

  useEffect(() => {
    const fetchDashboardFolders = async () => {
      const currentSubId = window.localStorage.getItem('subBoardId')
      // if (!currentSubId) return

      http
        .get(`/dashboard-folders/${apiGroup}`)
        .then(response => {
          console.log(response.data)
          dispatch({ type: Types.FETCH_ALL_SUBBOARD_WIDGET, payload: response.data })
          setActiveDashSubBoard(currentSubId ? currentSubId : response.data[0]?._id)
          props.location.pathname === '/dashboard' &&
            props.history.push({ search: `subBoardId=${currentSubId ? currentSubId : response.data[0]?._id || ''}` })
        })
        .catch(() => { })
    }
    if (apiGroup) {
      fetchDashboardFolders()
    }
  }, [apiGroup, path])

  const helpMenuLinkItems = [
    {
      title: 'Link',
      icon: <ApiDocumentationIcon />,
      link:
        'https://hjelp.tripletex.no/hc/no/articles/4409557117713-API-Opprette-brukern%C3%B8kkel-Integrasjon-for-sluttbruker-og-regnskapsf%C3%B8rer',
    },
    {
      title: 'Report a bug',
      icon: <BugReportIcon />,
      link: 'https://reportify.canny.io/bug-report',
    },
    {
      title: 'Submit feedback',
      icon: <FeedbackIcon />,
      link: 'https://reportify.canny.io/feedback',
    },
    {
      title: "What's new",
      icon: <WhatsNewIcon />,
      link: 'https://reportify.canny.io/',
    },
  ]

  const HelpMenu = ({ link, icon, title }) => {
    return (
      <a className='pm-item' href={link} target='_blank' rel='noopener noreferrer'>
        <div className='mr-3'>{icon}</div>
        {t(title)}
      </a>
    )
  }

  const handleLogout = () => {
    dispatch(logout(history))
  }

  const handleSidebar = () => {
    setShowSidebar(!showSidebar)
    props.expanded(!showSidebar)
  }

  const pagesToShow = public_pages.map(page => page.name)

  const showIcon = menu => {
    switch (menu) {
      case 'Dashboard':
        return path === '/dashboard' ? <DashboardOnSelectIcon /> : <DashboardIcon />
      case 'Cashflow':
        return path === '/cashflow' ? <CashFlowOnSelectIcon /> : <CashFlowIcon />
      case 'ProfitLoss':
        return path === '/profit' ? <ProfitLossOnSelectIcon /> : <ProfitLossIcon />
      case 'ApiAccount':
        return path === '/api-settings/api-accounts' ? <ConfigurationOnSelectIcon /> : <ConfigurationIcon />
      case 'BalanceSheet':
        return path === '/balance-sheet' ? <BalanceSheetOnSelectIcon /> : <BalanceSheetIcon />
      default:
        return <DashboardIcon />
    }
  }

  const handleHelpToggle = () => {
    setShowHelp(!showHelp)
  }

  const showWelcomeVideo = () => {
    dispatch(ShowWelcomeVideo(true))
  }

  const getActiveClassName = id => {
    return activeDashSubBoard === id ? 'icon--active' : ''
  }

  const dropdownMenu = () => (
    <div className='dropdown-wrap'>
      <div className='profile-dropdown'>
        <div className='profile-image'>
          <span className='user-img pd-img-wrap mr-3'>
            <Avatar user={user} />
          </span>
          <div className='profile-name'>
            <span className='text-truncate' style={{ maxWidth: '95%' }}>
              {user?.firstName}
            </span>
            <span className='text-truncate' title={user?.email} style={{ maxWidth: '95%' }}>
              {user?.email}
            </span>
          </div>
        </div>
        <div className='divider divider--full mb-2 mt-3'></div>
        <div className='profile-menu'>
          <Link to='/profile-settings/personal-information'>
            <div className='pm-item'>
              <div className='pm-item-icon mr-3'>
                <ProfileIcon />
              </div>
              <span>{t('Profile')}</span>
            </div>
          </Link>

          {role?.name === 'SuperAdmin' && (
            <Link to='/admin/dashboard'>
              <div className='pm-item'>
                <div className='pm-item-icon mr-3'>
                  <SuperAdminPanelIcon />
                </div>
                <span>{t('Super Admin Panel')}</span>
              </div>
            </Link>
          )}

          <div className='pm-item'>
            <div className='pm-item-icon mr-3'>
              <LanguageIcon />
            </div>
            <div className='d-flex justify-content-between align-items-center w-100'>
              <span>{t('Language')}</span>
              <Language />
            </div>
          </div>
          <div className='pm-item'>
            <div className='pm-item-icon mr-3'>
              <HelpIcon />
            </div>

            <div
              className='d-flex align-items-center w-100'
              onClick={() => handleHelpToggle()}
              style={{ cursor: 'pointer' }}>
              <span className='mr-2'>{t('Help')}</span>
              <img style={{ transform: `${showHelp ? 'rotate(180deg)' : 'rotate(0deg)'}` }} src={DownIcon} alt='' />
            </div>
          </div>

          {showHelp && (
            <div style={{ marginLeft: '25px' }}>
              <div className='pm-item' onClick={() => showWelcomeVideo()} style={{ cursor: 'pointer' }}>
                <div className='mr-3'>
                  <TutorialIcon />
                </div>
                {t('Video')}
              </div>
              {helpMenuLinkItems.map((item, index) => (
                <HelpMenu key={index} {...item} />
              ))}
            </div>
          )}
          <div className='divider divider--full my-2'></div>
          <div className='pm-item pm-logout pt-2 pb-0' onClick={() => handleLogout()}>
            <div className='pm-item-icon mr-3'>
              <LogoutIcon />
            </div>
            <span>{t('Logout')}</span>
          </div>
        </div>
      </div>
    </div>
  )

  const dropdownSubboard = () => (
    <div className='dropdown-wrap'>
      <div className='dropdown-sub-board'>
        <div className='nav-menus w-100' style={{ display: 'block' }}>
          {dashboardFolders.map((item, i) => {
            return (
              <button
                key={i}
                className={`side-bar-navs ${getActiveClassName(item._id)}`}
                onMouseOver={() => onHover(item._id)}
                onMouseLeave={() => onMouseOut(item._id)}
                onClick={() => handleSubBoard(item._id, item.name)}
                style={{ paddingLeft: '18px' }}>
                <div className='d-flex w-100'>
                  <span className='mr-2 folder-icon'>
                    <FolderIcon />
                  </span>
                  <span className='d-flex justify-content-between align-items-center' style={{ flex: '1 0 auto' }}>
                    <Tooltip title={t(item.name)}>
                      <span className='text-truncate  ml-2' style={{ maxWidth: '100px', paddingRight: '10px' }}>
                        {item.name}
                      </span>
                    </Tooltip>
                    {showSettings.includes(item._id) ? (
                      <span className='action-icon-wrap'>
                        <EditIcon onClick={(e) => handleUpdateSubBoard(e, item)} />
                        <DeleteIcon onClick={(e) => handleDeleteModal(e, item)} />
                      </span>
                    ) : null}
                  </span>
                </div>
              </button>
            )
          })}
          <ConsolidationModal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
            updateApi={() => handleDeleteSubBoard()}
            type='confirm'
          />

          <button className='side-bar-navs' style={{ paddingLeft: '18px' }} onClick={() => setShowSubBoardModal(true)}>
            <span className='mr-2'>
              <AddPageIcon size={14} className='mr-1' />
              {t('Create Sub-board')}
            </span>
            {/* <span className='nav-text'>{'Create Sub-board'}</span> */}
          </button>
        </div>
      </div>
    </div>
  )

  const handleSubmit = async () => {
    setLoadingState(true)
    try {
      const response = await http.post(`/dashboard-folders/${apiGroup}`, { name: subMenuName })
      if (response.status === 201 && response.data) {
        setActiveDashSubBoard(response.data._id)
        window.localStorage.setItem('subBoardId', response.data._id)
        dispatch(fetchAllSubBoardWidgetAction(apiGroup))
        props.history.push('/dashboard')
        props.history.push({ search: `subBoardId=${response.data._id || ''}` })
        customNotification('success', t('Subboard is created'))
      }
    } catch (ex) {
      console.log(ex)
      customNotification('error', t('Falied to created subboard'))
    } finally {
      setSubMenuName('')
      setShowSubBoardModal(false)
      setLoadingState(false) // make button loading
    }
  }

  const handleUpdateSubmit = async () => {
    setLoadingState(true)
    try {
      const response = await http.put(`/dashboard-folders/${apiGroup}/${updateSubBoardName.subBoardId}`, {
        name: subMenuName,
      })
      if (response.status === 200 && response.data) {
        // const previousData = dashboardFolders.filter(dashboardFolder => dashboardFolder._id !== response.data._id)
        dispatch(fetchAllSubBoardWidgetAction(apiGroup))
        customNotification('success', 'Subboard is updated')
      }
    } catch (ex) {
      console.log(ex)
      customNotification('error', t('Falied to update subboard'))
    } finally {
      setShowUpdateModal(false)
      setLoadingState(false) // make button loading
    }
  }

  const createSubBoard = () => {
    return (
      <Formik
        initialValues={{
          name: '',
        }}
        enableReinitialize={true}
        validationSchema={CreateSubBoardSchema}>
        {({ errors, touched, handleChange, values, setFieldTouched }) => (
          <div className='triplex-signup-form-bg d-flex align-items-center flex-wrap'>
            <Form className='w-100'>
              <div className='form-group'>
                <label className='form-label'>
                  {t('Sub-board name')}
                  <span className='required'>*</span>
                </label>
                <span>
                  <input
                    placeholder={t('Type name here')}
                    id='name'
                    type='name'
                    name='name'
                    className='form-control form-input'
                    value={values.name}
                    onChange={e => {
                      setFieldTouched('name')
                      setSubMenuName(e.target.value)
                      handleChange(e)
                    }}
                  />
                  {errors.name && touched.name && <span className='text-danger'> {t(errors.name)} </span>}
                </span>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    )
  }

  const updateSubBoard = () => {
    return (
      <Formik
        initialValues={{
          name: showUpdateModal ? updateSubBoardName.updateName : '',
        }}
        enableReinitialize={true}
        validationSchema={CreateSubBoardSchema}>
        {({ errors, touched, handleChange, values, setFieldTouched }) => (
          <div className='triplex-signup-form-bg d-flex align-items-center flex-wrap'>
            <Form className='w-100'>
              <div className='form-group'>
                <label className='form-label'>
                  {t('Sub-board name')}
                  <span className='required'>*</span>
                </label>
                <span>
                  <input
                    placeholder={t('Type name here')}
                    id='name'
                    type='name'
                    name='name'
                    className='form-control form-input'
                    value={values.name}
                    onChange={e => {
                      setFieldTouched('name')
                      setSubMenuName(e.target.value)
                      handleChange(e)
                    }}
                  />
                  {errors.name && touched.name && <span className='text-danger'> {t(errors.name)} </span>}
                </span>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    )
  }

  const onHover = id => {
    setShowSettings(prev => [...prev, id])
  }

  const onMouseOut = id => {
    setShowSettings(prev => [...prev.filter(item => item !== id)])
  }

  const handleSubBoard = (subBoardId, subBoardName) => {
    setActiveDashSubBoard(subBoardId)
    window.localStorage.setItem('subBoardId', subBoardId)
    props.history.push('/dashboard')
    props.history.push({ search: `subBoardId=${subBoardId || ''}` })
  }

  const handleCreateSubBoard = () => {
    setSubMenuName('')
    setShowSubBoardModal(true)
  }

  const handleUpdateSubBoard = (e, item) => {
    e.stopPropagation()
    setUpdateSubBoardName({
      subBoardId: item._id,
      updateName: item.name,
    })
    setShowUpdateModal(true)
  }

  const handleDeleteModal = (e, item) => {
    e.stopPropagation()
    setDeleteSubMenuId(item._id)
    setShowDeleteModal(true)
  }

  const handleDeleteSubBoard = async () => {
    try {
      const response = await http.delete(`/dashboard-folders/${apiGroup}/${deleteSubMenuId}`)
      if (response.status === 200 && response.data) {
        window.localStorage.removeItem('subBoardId')
        dispatch(fetchAllSubBoardWidgetAction(apiGroup))
        setDeleteSubMenuId('')
        customNotification('success', 'Sub-board is deleted')
      }
    } catch (ex) {
      console.log(ex)
      setDeleteSubMenuId('')
      customNotification('error', t('Falied to delete sub-board'))
    } finally {
      // setShowSubBoardModal(false)
      // setLoadingState(false) // make button loading
    }
  }

  return (
    <>
      {/* START sidebar section */}
      <aside className={`${showSidebar && 'expanded-sidebar '} sidebar`}>
        {/* START brand logo */}
        <div className='brand-sidebar mx-3'>
          <Link to='/dashboard' className='icon icon--logo'>
            <Logo />
          </Link>
          {<span className='reportify-name'>REPORTIFY</span>}
          <div className='close-sidebar' onClick={() => handleSidebar()}>
            <img src={CloseArrow} alt='' />
          </div>
        </div>
        {/* END brand logo*/}

        {/* START nav*/}

        <nav className='main-menu w-100' style={{ overflowY: 'auto' }}>
          {/* public board  */}
          {props.type === 'public' ? (
            <>
              <div className='nav-menus'>
                <NavLink
                  key={1}
                  to={`/public/board/${id}`}
                  data-menuid={1}
                  activeClassName='icon--active'
                  className='icon icon--has-hover-text'>
                  <DashboardIcon />
                  <span className='nav-text'>{t('Dashboard')}</span>
                </NavLink>
              </div>
              {pagesToShow.includes('PAGES.PROFIT_LOSS') ? (
                <div className='nav-menus'>
                  <NavLink
                    key={3}
                    to={`/public/profitloss/${id}`}
                    data-menuid={3}
                    activeClassName='icon--active'
                    className='icon icon--has-hover-text'>
                    <ProfitLossIcon />
                    <span className='nav-text'>{t('Profit Loss')}</span>
                  </NavLink>
                </div>
              ) : null}

              {pagesToShow.includes('PAGES.BALANCE_SHEET') ? (
                <div className='nav-menus'>
                  <NavLink
                    key={4}
                    to={`/public/balancesheet/${id}`}
                    data-menuid={4}
                    activeClassName='icon--active'
                    className='icon icon--has-hover-text'>
                    <BalanceSheetIcon />
                    <span className='nav-text'>{t('Balance Sheet')}</span>
                  </NavLink>
                </div>
              ) : null}

              {pagesToShow.includes('PAGES.CASHFLOW') ? (
                <div className='nav-menus'>
                  <NavLink
                    key={2}
                    to={`/public/cashflow/${id}`}
                    data-menuid={2}
                    activeClassName='icon--active'
                    className='icon icon--has-hover-text'>
                    <CashFlowIcon />
                    <span className='nav-text'>{t('Cashflow')}</span>
                  </NavLink>
                </div>
              ) : null}
            </>
          ) : (
            // eslint-disable-next-line array-callback-return
            allowedSideMenus?.map((menu, idx) => {
              return (
                <Fragment key={idx}>
                  {!['Profile', 'ApiAccount', 'Dashboard'].includes(menu.name) && (
                    <div className='nav-menus' key={idx}>
                      {!showSidebar ? (
                        <Tooltip title={t(menu.menuName)} placement='right'>
                          {/* <span className='nav-text text-truncate'>{item.name}</span> */}
                          <NavLink
                            key={idx}
                            to={menu.name === 'ApiAccount' ? `${menu.route}/api-accounts` : menu.route}
                            data-menuid={idx + 1}
                            activeClassName='icon--active'
                            className='side-bar-navs'>
                            <span className='mr-2'>{showIcon(menu.name)}</span>
                            <span className='nav-text'> {t(menu.menuName)}</span>
                            {menu.name === 'ApiAccount' && apiAccounts.length < 1 ? (
                              <span className='no-api-circle' title={t('Add API accounts first')}></span>
                            ) : null}
                          </NavLink>
                        </Tooltip>
                      ) : (
                        <NavLink
                          key={idx}
                          to={menu.name === 'ApiAccount' ? `${menu.route}/api-accounts` : menu.route}
                          data-menuid={idx + 1}
                          activeClassName='icon--active'
                          className='icon icon--has-hover-text side-bar-navs'>
                          <span className='mr-2'>{showIcon(menu.name)}</span>
                          <span className='nav-text'> {t(menu.menuName)}</span>
                          {menu.name === 'ApiAccount' && apiAccounts.length < 1 ? (
                            <span className='no-api-circle' title={t('Add API accounts first')}></span>
                          ) : null}
                        </NavLink>
                      )}
                    </div>
                  )}
                  {menu.name === 'Dashboard' && (
                    <>
                      <div className='nav-menus' key={idx}>
                        {showSidebar ? (
                          <>
                            <div
                              key={idx}
                              data-menuid={idx + 1}
                              onClick={() => setOpenCollapse(!openCollapse)}
                              className={`icon icon--has-hover-text side-bar-navs ${path === '/dashboard' ? 'icon--active' : ''
                                }`}>
                              <div className='d-flex align-items-center ' style={{ cursor: 'pointer' }}>
                                <span className='mr-2'>{showIcon(menu.name)}</span>
                                <span className='nav-text'> {t(menu.menuName)}</span>
                              </div>
                              <img className='ml-2 mt-0' style={{ transform: openCollapse ? 'rotate(90deg)' : 'rotate(0deg)', transition: 'ease-in-out .3s all' }} src={RightIcon} alt='' />
                            </div>

                            {openCollapse && (
                              <>
                                {dashboardFolders.map((item, i) => {
                                  return (
                                    <button
                                      key={i}
                                      className={`side-bar-navs ${getActiveClassName(item._id)}`}
                                      onMouseOver={() => onHover(item._id)}
                                      onMouseLeave={() => onMouseOut(item._id)}
                                      onClick={() => handleSubBoard(item._id, item.name)}
                                      style={{ paddingLeft: '25px' }}>
                                      <div className='d-flex w-100'>
                                        <span className='mr-2 folder-icon'>
                                          <FolderIcon />
                                        </span>
                                        <span
                                          className='nav-text d-flex justify-content-between align-items-center'
                                          style={{ flex: '1 0 auto' }}>
                                          <Tooltip title={item.name.length > 13 ? t(item.name) : ''}>
                                            <span className='nav-text text-truncate' style={{ paddingRight: '10px', maxWidth: '120px' }}>
                                              {item.name}
                                            </span>
                                          </Tooltip>
                                          {showSettings.includes(item._id) ? (
                                            <span className='action-icon-wrap'>
                                              <EditIcon onClick={(e) => handleUpdateSubBoard(e, item)} />
                                              <DeleteIcon onClick={(e) => handleDeleteModal(e, item)} />
                                            </span>
                                          ) : null}
                                        </span>
                                      </div>
                                    </button>
                                  )
                                })}
                                <ConsolidationModal
                                  show={showDeleteModal}
                                  onHide={() => setShowDeleteModal(false)}
                                  updateApi={() => handleDeleteSubBoard()}
                                  type='confirm'
                                />

                                <button
                                  className='side-bar-navs'
                                  style={{ paddingLeft: '25px', justifyContent: 'flex-start' }}
                                  onClick={() => handleCreateSubBoard()}>
                                  <span className='mr-2'>
                                    <AddPageIcon size={14} />
                                  </span>
                                  <span className='nav-text'>{t('Create Sub-board')}</span>
                                </button>
                              </>
                            )}
                          </>
                        ) : (
                          <Dropdown
                            overlay={dropdownSubboard()}
                            placement='bottomLeft'
                            getPopupContainer={() => document.querySelector('.sidebar')}
                            overlayClassName='sidebar-profile-dropdown'
                            trigger={['click']}
                            visible={showDropDownDashboard}
                            onVisibleChange={val => setShowDropDownDashboard(val)}>
                            <div
                              className='side-bar-navs overflow-hidden nav-menus w-100'
                              style={{ cursor: 'pointer', marginTop: '23px' }}>
                              {!showSidebar ? (
                                <Tooltip title={t(menu.menuName)} placement='right'>
                                  <span className='mr-2'>{showIcon(menu.name)}</span>
                                </Tooltip>
                              ) : (
                                <span className='mr-2'>{showIcon(menu.name)}</span>
                              )}
                              <div className='d-flex justify-content-between w-100 nav-text'>
                                <div className='d-flex flex-column' style={{ width: '150px' }}>
                                  <span className='user-name pl-2 text-truncate'> {user?.firstName} </span>
                                </div>
                                <img className='ml-2 mt-1' src={DownIcon} alt='' />
                              </div>
                            </div>
                          </Dropdown>
                        )}
                      </div>
                    </>
                  )
                  }
                </Fragment>
              )
            })
          )}
        </nav>
        <div className='w-100'>
          {allowedSideMenus?.map((menu, idx) => {
            if (menu.name === 'ApiAccount') {
              return (
                <div className='nav-menus w-100' style={{ overflow: 'hidden' }} key={idx}>
                  {!showSidebar ? (
                    <Tooltip title={t('Configuration')} placement='right'>
                      <NavLink
                        key={idx}
                        to={menu.name === 'ApiAccount' ? `${menu.route}/api-accounts` : menu.route}
                        data-menuid={idx + 1}
                        activeClassName='icon--active'
                        className='side-bar-navs'>
                        <span className='mr-2'>{showIcon(menu.name)}</span>
                        <span className='nav-text'> {t('Configuration')}</span>
                        {menu.name === 'ApiAccount' && apiAccounts.length < 1 ? (
                          <span className='no-api-circle' title={t('Add API accounts first')}></span>
                        ) : null}
                      </NavLink>
                    </Tooltip>
                  ) : (
                    <NavLink
                      key={idx}
                      to={menu.name === 'ApiAccount' ? `${menu.route}/api-accounts` : menu.route}
                      data-menuid={idx + 1}
                      activeClassName='icon--active'
                      className='icon icon--has-hover-text side-bar-navs'>
                      <span className='mr-2'>{showIcon(menu.name)}</span>
                      <span className='nav-text'> {t('Configuration')}</span>
                      {menu.name === 'ApiAccount' && apiAccounts.length < 1 ? (
                        <span className='no-api-circle' title={t('Add API accounts first')}></span>
                      ) : null}
                    </NavLink>
                  )}
                </div>
              )
            }
          })}
        </div>
        <Dropdown
          overlay={dropdownMenu()}
          placement='topLeft'
          getPopupContainer={() => document.querySelector('.sidebar')}
          overlayClassName='sidebar-profile-dropdown'
          trigger={['click']}
          open={showDropDown}
          onOpenChange={val => setShowDropDown(val)}>
          <div className='d-flex side-bar-navs overflow-hidden nav-menus w-100' style={{ borderTop: '1px solid #F2F2F2', cursor: 'pointer', paddingRight: '20px' }}>
            <span className='user-img'>
              <Avatar user={user} />
            </span>
            <div className='d-flex justify-content-between w-100 nav-text'>
              <div className='d-flex flex-column' style={{ width: '290px' }}>
                <span className='user-name pl-2 text-truncate'> {user?.firstName} </span>
              </div>
              <img className='ml-2 mt-1' style={{ transform: showDropDown ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'ease-in-out .3s all' }} src={DownIcon} alt='' />
            </div>
          </div>
        </Dropdown>
      </aside >


      <DashboardModal
        show={showSubBoardModal}
        onHide={() => setShowSubBoardModal(false)}
        cancelTitle='Not Now'
        confirmTitle={'Confirm'}
        onConfirm={() => (handleSubmit())}
        clearFormData={() => {
          setSubMenuName('')
        }}
        loading={loading}
      >
        {createSubBoard()}
      </DashboardModal>

      <DashboardModal
        show={showUpdateModal}
        onHide={() => setShowUpdateModal(false)}
        cancelTitle='Not Now'
        confirmTitle={'Update'}
        onConfirm={() => (handleUpdateSubmit())}
        clearFormData={() => {
          setUpdateSubBoardName(updateSubBoardName)
        }}
        loading={loading}
      >
        {updateSubBoard()}
      </DashboardModal>
    </>
  )
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  }
}

export default withRouter(
  connect(mapStateToProps, {
    logout,
  })(Sidebar)
)
