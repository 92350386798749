import * as Types from '../actions/type'

const profitReducer = (state = [], action) => {
  switch (action.type) {
    case Types.LOAD_PROFIT:
      return action.payload.profitLoss
    default:
      return state
  }
}

export default profitReducer
