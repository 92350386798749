import * as React from 'react'
const AiRecentlyIcon = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width={28} height={28} fill='none' {...props}>
    <circle cx={14} cy={14} r={14} fill='#2D2D2D' fillOpacity={0.04} />
    <g clipPath='url(#a)'>
      <path
        fill='#2D2D2D'
        d='M14 7.333A6.667 6.667 0 1 1 7.333 14h1.334a5.334 5.334 0 1 0 1.243-3.423L11.333 12h-4V8l1.632 1.63A6.653 6.653 0 0 1 14 7.334Zm.667 3.334v3.056l2.162 2.162-.944.944-2.552-2.554v-3.608h1.334Z'
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M6 6h16v16H6z' />
      </clipPath>
    </defs>
  </svg>
)
export default AiRecentlyIcon
