import http from '../../services/httpService'
import { store } from '../index'
import * as Types from './type'

//Fetch all widgets
export const fetchAllWidgets = () => dispatch => {
  http
    .get('/widgets/configurations')
    .then(response => {
      if (Array.isArray(response.data) && response.data.success !== false) {
        dispatch({
          type: Types.FETCH_ALL_WIDGETS,
          payload: response.data,
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
}

//Top 10 Customers
export const LoadTopCustomers = () => dispatch => {
  dispatch({
    type: Types.TOP_CUSTOMERS,
    loading: true,
  })
  let groupAccId = ''
  if (store.getState().main.apiGroup === '') {
    groupAccId = store.getState().public.groupId
  } else {
    groupAccId = store.getState().main.apiGroup
  }
  http
    .get('/widgets/top-ten/customers', {
      params: {
        groupId: groupAccId,
        startDate: store.getState().main.w_start_date,
        endDate: store.getState().main.w_end_date,
      },
      // For public board sharing
      headers: {
        'board-id': store.getState().public.public_board_id,
      },
    })
    .then(response => {
      if (Array.isArray(response.data) && response.data.success !== false) {
        dispatch({
          type: Types.TOP_CUSTOMERS,
          payload: response.data,
          loading: false,
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
}

//Top 10 Suppliers
export const LoadTopSuppliers = () => dispatch => {
  dispatch({
    type: Types.TOP_SUPPLIERS,
    loading: true,
  })
  let groupAccId = ''
  if (store.getState().main.apiGroup === '') {
    groupAccId = store.getState().public.groupId
  } else {
    groupAccId = store.getState().main.apiGroup
  }
  http
    .get('/widgets/top-ten/suppliers', {
      params: {
        groupId: groupAccId,
        startDate: store.getState().main.w_start_date,
        endDate: store.getState().main.w_end_date,
      },
      // For public board sharing
      headers: {
        'board-id': store.getState().public.public_board_id,
      },
    })
    .then(response => {
      if (Array.isArray(response.data) && response.data.success !== false) {
        dispatch({
          type: Types.TOP_SUPPLIERS,
          payload: response.data,
          loading: false,
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
}

//Top 5 Expenses
export const LoadTopExpenses = () => dispatch => {
  dispatch({
    type: Types.TOP_EXPENSES,
    loading: true,
  })
  let groupAccId = ''
  if (store.getState().main.apiGroup === '') {
    groupAccId = store.getState().public.groupId
  } else {
    groupAccId = store.getState().main.apiGroup
  }

  let label = [],
    data = []

  http
    .get('/widgets/top-five/expenses', {
      params: {
        groupId: groupAccId,
        startDate: store.getState().main.w_start_date,
        endDate: store.getState().main.w_end_date,
      },
      // For public board sharing
      headers: {
        'board-id': store.getState().public.public_board_id,
      },
    })
    .then(response => {
      // eslint-disable-next-line array-callback-return
      response.data.map(group => {
        if (group.closingBalance) {
          data.push(group.closingBalance)
        }
        if (group.account) {
          label.push(group.account.name)
        }
      })
      dispatch({
        type: Types.TOP_EXPENSES,
        payload: {
          data: data,
          label: label,
        },
        loading: false,
      })
    })
    .catch(error => {
      console.log(error)
    })
}

//Top 10 Costs
export const LoadTop10Costs = () => dispatch => {
  dispatch({
    type: Types.TOP_10_COSTS,
    loading: true,
  })
  let groupAccId = ''
  if (store.getState().main.apiGroup === '') {
    groupAccId = store.getState().public.groupId
  } else {
    groupAccId = store.getState().main.apiGroup
  }

  http
    .get('/widgets/top-ten/expenses', {
      params: {
        groupId: groupAccId,
        startDate: store.getState().main.w_start_date,
        endDate: store.getState().main.w_end_date,
      },
      // For public board sharing
      headers: {
        'board-id': store.getState().public.public_board_id,
      },
    })
    .then(response => {
      if (Array.isArray(response.data) && response.data.success !== false) {
        dispatch({
          type: Types.TOP_10_COSTS,
          payload: response.data,
          loading: false,
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
}

//Top 10 Products
export const LoadTop10Products = () => dispatch => {
  dispatch({
    type: Types.TOP_10_PRODUCTS,
    loading: true,
  })
  let groupAccId = ''
  if (store.getState().main.apiGroup === '') {
    groupAccId = store.getState().public.groupId
  } else {
    groupAccId = store.getState().main.apiGroup
  }

  http
    .get('/widgets/top-ten/products', {
      params: {
        groupId: groupAccId,
        startDate: store.getState().main.w_start_date,
        endDate: store.getState().main.w_end_date,
      },
      // For public board sharing
      headers: {
        'board-id': store.getState().public.public_board_id,
      },
    })
    .then(response => {
      if (Array.isArray(response.data) && response.data.success !== false) {
        dispatch({
          type: Types.TOP_10_PRODUCTS,
          payload: response.data,
          loading: false,
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
}

//Top 10 Largest Transaction
export const LoadLargestTransaction = () => dispatch => {
  dispatch({
    type: Types.LARGEST_TRANSACTION,
    loading: true,
  })
  let groupAccId = ''
  if (store.getState().main.apiGroup === '') {
    groupAccId = store.getState().public.groupId
  } else {
    groupAccId = store.getState().main.apiGroup
  }

  http
    .get('/widgets/top-ten/largest-transactions', {
      params: {
        groupId: groupAccId,
        startDate: store.getState().main.w_start_date,
        endDate: store.getState().main.w_end_date,
      },
      // For public board sharing
      headers: {
        'board-id': store.getState().public.public_board_id,
      },
    })
    .then(response => {
      if (Array.isArray(response.data) && response.data.success !== false) {
        dispatch({
          type: Types.LARGEST_TRANSACTION,
          payload: response.data,
          loading: false,
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
}

//Turnover widget
export const LoadTurnoverWidget = () => dispatch => {
  let groupAccId = ''
  if (store.getState().main.apiGroup === '') {
    groupAccId = store.getState().public.groupId
  } else {
    groupAccId = store.getState().main.apiGroup
  }

  http
    .get('/widgets/turnover', {
      params: {
        groupId: groupAccId,
        startDate: store.getState().main.w_start_date,
        endDate: store.getState().main.w_end_date,
      },
      // For public board sharing
      headers: {
        'board-id': store.getState().public.public_board_id,
      },
    })
    .then(response => {
      if (response.data.success !== false) {
        dispatch({
          type: Types.TURNOVER_WIDGET,
          payload: response.data,
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
}

//Custom widget fetch
export const LoadCustomWidget = (id, dashboardId) => dispatch => {
  let groupAccId = ''
  if (store.getState().main.apiGroup === '') {
    groupAccId = store.getState().public.groupId
  } else {
    groupAccId = store.getState().main.apiGroup
  }

  http
    .get(`/widgets/${id}/custom/${dashboardId}`, {
      params: {
        groupId: groupAccId,
        startDate: store.getState().main.w_start_date,
        endDate: store.getState().main.w_end_date,
      },
      // For public board sharing
      headers: {
        'board-id': store.getState().public.public_board_id,
      },
    })
    .then(response => {
      if (response.data.success !== false) {
        dispatch({
          type: Types.CUSTOM_WIDGETS,
          payload: response.data,
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
}

//MRR widget
export const LoadMrrWidget = () => dispatch => {
  dispatch({
    type: Types.MRR_WIDGET,
    loading: true,
  })
  let groupAccId = ''
  if (store.getState().main.apiGroup === '') {
    groupAccId = store.getState().public.groupId
  } else {
    groupAccId = store.getState().main.apiGroup
  }
  http
    .get('/widgets/mrr', {
      params: {
        groupId: groupAccId,
        startDate: store.getState().main.w_start_date,
        endDate: store.getState().main.w_end_date,
      },
      // For public board sharing
      headers: {
        'board-id': store.getState().public.public_board_id,
      },
    })
    .then(response => {
      if (response.data.success !== false) {
        dispatch({
          type: Types.MRR_WIDGET,
          payload: response.data.mrr,
          prev: response.data.previousMrr,
          loading: false,
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
}

//load ai widget
export const LoadAIWidget = aiQueryId => dispatch => {
  let groupAccId = ''
  if (store.getState().main.apiGroup === '') {
    groupAccId = store.getState().public.groupId
  } else {
    groupAccId = store.getState().main.apiGroup
  }

  http
    .get(`/widgets/ai`, {
      params: {
        groupId: groupAccId,
        aiQueryId,
      },
      // For public board sharing
      headers: {
        'board-id': store.getState().public.public_board_id,
      },
    })
    .then(response => {
      if (response.data.success !== false) {
        dispatch({
          type: Types.AI_WIDGET,
          payload: response.data,
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
}
