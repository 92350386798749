import nProgress from 'nprogress'
import http from '../../services/httpService'
import { store } from '../index'
import * as Types from './type'

export const LoadProfit = groupId => dispatch => {
  nProgress.start()
  let profitLossHeadingItems = {}
  let profitLossSubItems = {}
  let months = []

  function populate_sub_items(subitem, count, group) {
    if (profitLossSubItems[group][count]) {
      profitLossSubItems[group][count].push(subitem.amount)
    } else {
      profitLossSubItems[group][count] = ['child']
      profitLossSubItems[group][count].push(subitem.number)
      if (localStorage.getItem('i18nextLng') == 'en') {
        profitLossSubItems[group][count].push(subitem.title.en)
      } else {
        profitLossSubItems[group][count].push(subitem.title.no)
      }
      profitLossSubItems[group][count].push(subitem.amount)
    }
  }

  http
    .get('/profit-loss', {
      params: {
        groupId: groupId ? groupId : store.getState().main.apiGroup,
        startDate: store.getState().main.start_date,
        endDate: store.getState().main.end_date,
      },
      // For public board sharing
      headers: {
        'board-id': store.getState().public.public_board_id,
      },
    })
    .then(response => {
      response.data.forEach(monthData => {
        months.push(monthData.month) // get months

        monthData.groups.forEach(item => {
          if (profitLossHeadingItems[item.group] && profitLossHeadingItems[item.group].length) {
            profitLossHeadingItems[item.group].push(item.totalAmountOfGroup)
            // F_CF[item.group].push(item.title.en)
          } else {
            profitLossHeadingItems[item.group] = ['parent']
            if (localStorage.getItem('i18nextLng') == 'en') {
              profitLossHeadingItems[item.group].push(item.groupTitle.en)
            } else {
              profitLossHeadingItems[item.group].push(item.groupTitle.no)
            }
            profitLossHeadingItems[item.group].push(item.totalAmountOfGroup)
          }

          // handling subitems
          item.subItems.forEach((subitem, index) => {
            if (profitLossSubItems[item.group]) {
              populate_sub_items(subitem, index, item.group)
            } else {
              profitLossSubItems[item.group] = {}
              populate_sub_items(subitem, index, item.group)
            }
          })
        })
      })

      dispatch({
        type: Types.LOAD_PROFIT,
        payload: {
          profitLoss: {
            parent: profitLossHeadingItems,
            child: profitLossSubItems,
            months: months,
          },
        },
      })
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => {
      nProgress.done()
    })
}
