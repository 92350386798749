import nProgress from 'nprogress'
import http from '../../services/httpService'
import { customNotification } from '../../util/customNotification'
import { store } from '../index'
import * as Types from './type'
import { LoadCustomWidget } from './widgetsAction'

export const LoadApiGroup = () => dispatch => {
  nProgress.start()
  http
    .get('/group-account')
    .then(response => {
      dispatch({
        type: Types.LOAD_GROUPACCOUNT,
        payload: {
          groupAccount: response.data,
        },
      })

      let defaultApi = store.getState().main.apiGroup

      if (!defaultApi) {
        dispatch({
          type: Types.APIGROUP_CHANGE,
          payload: response.data[0]._id,
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => {
      nProgress.done()
    })
}

export const FetchGroupAccById = id => dispatch => {
  nProgress.start()
  http
    .get(`/group-account/${id}`)
    .then(response => {
      dispatch({
        type: Types.UPDATE_GROUPACCOUNT,
        payload: {
          groupAccount: response.data,
        },
      })
    })
    .finally(() => {
      nProgress.done()
    })
}

export const UpdateGroupAccount = (id, data) => dispatch => {
  nProgress.start()
  http
    .put(`/group-account/${id}`, data)
    .then(response => {
      dispatch({
        type: Types.UPDATE_GROUPACCOUNT,
        payload: {
          groupAccount: response.data,
        },
      })
    })
    .finally(() => {
      nProgress.done()
    })
}

export const AddApiGroup = data => dispatch => {
  nProgress.start()
  http
    .post('/group-account', data)
    .then(response => {
      dispatch({
        type: Types.ADD_GROUPACCOUNT,
        payload: {
          groupAccount: response.data,
        },
      })
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => {
      nProgress.done()
    })
}

export const DeleteApiGroup = id => dispatch => {
  nProgress.start()
  http
    .delete(`/group-account/${id}`, {
      params: {
        forced: 'forced',
      },
    })
    .then(response => {
      console.log(response)
      if (response.data?.isDeleted === true) {
        dispatch({
          type: Types.DELETE_GROUPACCOUNT,
          payload: id,
        })
        customNotification('success', 'Board Delete Successfully')

        let groupAcc = store.getState().groupAccount
        window.localStorage.removeItem('apiGroupName')

        if (groupAcc.length !== 0) {
          dispatch({
            type: Types.APIGROUP_CHANGE,
            payload: groupAcc[0]._id,
          })
        }

        if (groupAcc.length === 0) {
          dispatch({
            type: Types.LAST_APIGROUP_DELETE,
          })
        }

        dispatch(LoadApiGroup())
      }
    })
    .catch(error => {
      if (error.response?.data?.message === 'The user must have at least 1 assigned group.') {
        customNotification('error', 'The user must have at least 1 assigned group')
      } else if (
        error.response?.data?.message === 'Unauthorized access! Only owner of this group is able to delete the group.'
      ) {
        customNotification('error', 'Only owner of this group is able to delete the group.')
      }
    })
    .finally(() => {
      nProgress.done()
    })
}

export const UpdateApiGroupsWidgets = (data, id, actionType, customWidgetId) => dispatch => {
  nProgress.start()
  const payload = {
    groupId: id,
    widgets: data,
  }
  http
    .patch(`/widgets/board-preference/${id}/update`, payload)
    .then(response => {
      dispatch(FetchGroupAccById(response?.data?.groupId))
      if (customWidgetId) {
        dispatch(LoadCustomWidget(customWidgetId))
      }
      if (response.status === 200) {
        let msg = ''
        let msgType = ''
        switch (actionType) {
          case 'widget-add':
            msg = 'Widget added successfully'
            msgType = 'success'
            break
          case 'widget-update':
            msg = 'Board Updated Successfully'
            msgType = 'success'
            break
          case 'custom-widget-update':
            msg = 'Custom widget updated successfully'
            msgType = 'success'
            break
          case 'delete':
            msg = 'Widget deleted successfully'
            msgType = 'success'
            break
          case 'view':
            msg = 'Widget view changed Successfully'
            msgType = 'success'
            break
          default:
            msg = 'Board Updated Successfully'
            msgType = 'success'
            break
        }
        customNotification(msgType, msg)
      }
    })
    .catch(error => {
      console.log(error)
      customNotification('error', 'Board Failed to Update')
    })
    .finally(() => {
      nProgress.done()
    })
}

// Create custom widget
export const CreateCustomWidget = (data, id) => dispatch => {
  nProgress.start()
  http
    .post(`/widgets/${id}/custom/create`, data)
    .then(response => {
      dispatch(FetchGroupAccById(id))
      if (response.status === 200) {
        customNotification('success', 'Custom widget added successfully')
      }
    })
    .catch(() => {
      customNotification('error', 'Failed to create custom widget')
    })
    .finally(() => {
      nProgress.done()
    })
}
