import { Modal } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getShortMonthNameWithDate } from '../../util/functions'

const SyncModal = ({ show, onHide, syncData, lastSynced }) => {
  const { t } = useTranslation()

  const handleClick = () => {
    syncData()
    onHide()
  }

  return (
    <Modal className='create-new-board-modal' title={t('Sync Board')} open={show} onCancel={onHide} footer={null}>
      <div className='cnbm-delete-comfirm'>
        <div>
          <div className='bsw-date'>
            {t('Last synced on')} {lastSynced ? getShortMonthNameWithDate(lastSynced) : t('never')}
          </div>
          {t('Sync this board?')} {t('Sync board hover')}.
        </div>
        <div className='delete-btn-wrap'>
          <button className='btn-secondary' onClick={() => onHide()}>
            {t('Cancel')}
          </button>
          <button className='btn-outline-light' onClick={() => handleClick()}>
            <span>
              <span>{t('Sync')}</span>
            </span>
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default SyncModal
