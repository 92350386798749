const init = {
  name: localStorage.getItem('name') || '',
  email: localStorage.getItem('email') || '',
  phoneNumber: localStorage.getItem('phoneNumber') || '',
  companyName: localStorage.getItem('companyName') || '',
  avatar: localStorage.getItem('avatar') || '',
}

const userReducer = (state = init, action) => {
  const newState = { ...state }

  switch (action.type) {
    default:
      return newState
  }
}

export default userReducer
