import * as React from 'react'

function SyncBoardIcon(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={20}
      height={20}
      viewBox='0 0 20 20'
      {...props}>
      <path d='M10 15.453A5.453 5.453 0 014.547 10c0-.922.23-1.785.629-2.55L3.852 6.12A7.279 7.279 0 002.727 10 7.27 7.27 0 0010 17.273V20l3.637-3.637L10 12.727zm0 0M10 2.727V0L6.363 3.637 10 7.273V4.547A5.453 5.453 0 0115.453 10c0 .922-.23 1.785-.629 2.55l1.324 1.329A7.279 7.279 0 0017.273 10 7.27 7.27 0 0010 2.727zm0 0' />
    </svg>
  )
}
export default SyncBoardIcon
