import { Spin } from 'antd'
import React from 'react'
import { LoadingOutlined } from '@ant-design/icons';

const SpinLoaderWrapper = ({ children, loading }) => {
    const loadingIcon = <LoadingOutlined style={{ fontSize: 18, color: '#f76552' }} spin />;
    return (
        <Spin spinning={loading} indicator={loadingIcon}>
            {children}
        </Spin>
    )
}

export default SpinLoaderWrapper