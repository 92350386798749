import * as React from 'react'

function EditIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} fill='none' {...props}>
      <path
        stroke='#BCC4D0'
        d='M7.262 2.476h-4.87A1.392 1.392 0 0 0 1 3.868v9.74A1.391 1.391 0 0 0 2.392 15h9.74a1.391 1.391 0 0 0 1.392-1.392v-4.87M12.48 1.432a1.476 1.476 0 0 1 2.088 2.088l-6.61 6.61-2.783.695.695-2.783 6.61-6.61Z'
      />
    </svg>
  )
}

export default EditIcon
