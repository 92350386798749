import * as React from 'react'
const SyncTickIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} fill='none'>
    <path
      fill='#2D2D2D'
      d='M7.999 15.5a7.5 7.5 0 1 1 0-14.999 7.5 7.5 0 0 1 0 15Zm0-1.5a6 6 0 1 0 0-12 6 6 0 0 0 0 12Zm-.748-3L4.07 7.819l1.06-1.06L7.251 8.88l4.242-4.243 1.061 1.06-5.303 5.304Z'
    />
  </svg>
)
export default SyncTickIcon
