import * as Types from '../actions/type'

const init = {
  dashboardWidget: [],
  currentSubId: window.localStorage.getItem('subBoardId') || '',
}

const dashboardSubBoardReducer = (state = init, action) => {
  switch (action.type) {
    case Types.FETCH_ALL_SUBBOARD_WIDGET:
      return {
        ...state,
        dashboardWidget: action.payload,
      }
    case Types.SET_SUB_BOARD_ID:
      return {
        ...state,
        currentSubId: action.payload,
      }
    default:
      return state
  }
}

export default dashboardSubBoardReducer
