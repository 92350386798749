import { convertDate, FirstDayOfYear, LastDayOfYear } from '../../util/functions'
import * as Types from '../actions/type'

const init = {
  apiGroup: window.localStorage.getItem('apiGroupName') || '',
  w_start_date: convertDate(FirstDayOfYear()),
  w_end_date: convertDate(LastDayOfYear()),
  start_date: convertDate(FirstDayOfYear()),
  end_date: convertDate(LastDayOfYear()),
  activeLocale: 'nor',
  showWelcomeVideo: false,
  isWidgetDraggable: '',
}

const mainReducer = (state = init, action) => {
  switch (action.type) {
    case Types.StartDate_CHANGE:
      return {
        ...state,
        start_date: action.payload,
      }
    case Types.EndDate_CHANGE:
      return {
        ...state,
        end_date: action.payload,
      }
    case Types.W_StartDate_CHANGE:
      return {
        ...state,
        w_start_date: action.payload,
      }
    case Types.W_EndDate_CHANGE:
      return {
        ...state,
        w_end_date: action.payload,
      }
    case Types.APIGROUP_CHANGE:
      return {
        ...state,
        apiGroup: action.payload,
      }
    case Types.LAST_APIGROUP_DELETE:
      return {
        ...state,
        apiGroup: '',
      }
    case Types.UPDATE_LOCALE:
      return {
        ...state,
        activeLocale: action.payload,
      }
    case Types.SHOW_WELCOME_VIDEO:
      return {
        ...state,
        showWelcomeVideo: action.payload,
      }
    case Types.IS_DRAGGABLE_WIDGET:
      return {
        ...state,
        isWidgetDraggable: action.payload,
      }
    default:
      return state
  }
}

export default mainReducer
