import * as Types from '../actions/type'

const invitations = []

const invitationReducer = (state = invitations, action) => {
  switch (action.type) {
    case Types.FETCH_ALL_INVITATIONS:
      return [...action.payload]
    default:
      return state
  }
}

export default invitationReducer
