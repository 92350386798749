import React from 'react'

function CirclePlusIcon(props) {
  return (
    <svg
      width={14}
      height={14}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        opacity={0.7}
        d='M7 4.6v4.8M4.6 7h4.8M13 7A6 6 0 111 7a6 6 0 0112 0z'
        stroke='#2D2D2D'
        strokeWidth={1.4}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default CirclePlusIcon
