import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import { useTranslation } from 'react-i18next'
import SpinLoaderWrapper from '../common/SpinLoaderWrapper'
// import AddPageIcon from '../../../images/svg/addpage'

const DashboardModal = React.memo(({ show, onHide, loading, children, onConfirm, disabled, clearFormData, ...props }) => {
  const { t } = useTranslation()
  const handleConfirm = () => {
    onConfirm()
    // onHide()
    clearFormData()
  }

  return (
    <Modal
      className='create-new-board-modal'
      title={t(props.title)}
      centered={true}
      open={show}
      onCancel={onHide}
      footer={null}>
      <>
        <div className='create-new-board-modal-content'>
          <div className='cnbm-body'>
            {children}
            <br />
          </div>
          <div className='cnbm-footer'>
            <button className='btn-secondary' onClick={() => onHide(false)}>
              {t(props.cancelTitle)}
            </button>
            <SpinLoaderWrapper loading={loading}>
              <button
                className={`btn-outline-light ${disabled && 'text-dark'}`}
                onClick={() => handleConfirm()}
              >
                <span>
                  {/* <AddPageIcon className='mr-2 add-page-icon' /> */}
                  <span>{t(props.confirmTitle)}</span>
                </span>
              </button>

            </SpinLoaderWrapper>
          </div>
        </div>
      </>
    </Modal>
  )
})

export default DashboardModal
