import { Input, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Slider from 'react-slick'
import AISliderIcon from '../../images/svg/AISliderIcon'
import AiMagicIcon from '../../images/svg/AiMagicIcon'
import AiRecentlyIcon from '../../images/svg/AiRecentlyIcon'
import SearchIcon from '../../images/svg/search'
import httpService from '../../services/httpService'
import { customNotification } from '../../util/customNotification'

const AiModalStep1 = ({ setQues, setStep, ques, setResult }) => {
  const { t } = useTranslation()
  const [inputVal, setInputVal] = useState(ques ? ques : '')
  const { apiGroup } = useSelector(state => state.main)

  const [suggestedData, setSuggestedData] = useState([])
  const [recentSearchedData, setRecentSearchedData] = useState([])
  const [showAllSearchedData, setShowAllSearchedData] = useState(false)
  const [loading, setLoading] = useState(false)

  const WordCount = () => {
    return inputVal?.split(' ').filter(n => n !== '').length
  }

  useEffect(() => {
    const getSuggestedData = async () => {
      await httpService
        .get('/ai/predefine?page=1&pageSize=100')
        .then(res => {
          if (res.data.success !== false) {
            setSuggestedData(res.data.data)
          }
        })
        .catch(err => console.log(err))
    }

    const getRecentSearchedData = async () => {
      await httpService
        .get(`/ai/recent/${apiGroup}?page=1&pageSize=100`)
        .then(res => {
          if (res.data.success !== false) {
            setRecentSearchedData(res.data.data)
          }
        })
        .catch(err => console.log(err))
    }

    getSuggestedData()
    getRecentSearchedData()
  }, [apiGroup])

  //slider settings
  function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return (
      <div className={className} style={{ ...style }} onClick={onClick}>
        <AISliderIcon />
      </div>
    )
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return (
      <div className={className} style={{ ...style }} onClick={onClick}>
        <AISliderIcon />
      </div>
    )
  }

  const settings = {
    className: 'slider variable-width',
    dots: false,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  const handleQuestionSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    const re = await httpService
      .get(`ai/${apiGroup}?search=${inputVal}`)
      .then(res => {
        console.log('res', res)
        setResult(res.data.data)
        setLoading(false)
        setStep(2)
        setQues('')
      })
      .catch(err => {
        setLoading(false)

        customNotification('error', t('This group does not have any api accounts'))
      })
  }

  return (
    <div className='ai-body'>
      <div className='ai-slider'>
        <Slider {...settings}>
          {suggestedData.map((item, idx) => (
            <div className='ai-slider-data' key={idx} onClick={() => setInputVal(item.query)}>
              <AiMagicIcon /> {item.query}
            </div>
          ))}
        </Slider>
      </div>

      {/* search input */}
      <div className='search-data-wrap'>
        <form onSubmit={handleQuestionSubmit} className='d-flex w-100'>
          <Input
            maxLength={50}
            prefix={<SearchIcon />}
            value={inputVal}
            onChange={e => setInputVal(e.target.value)}
            className='search-account-input'
            placeholder={t('Ask a question')}
          />
          <Spin spinning={loading}>
            <button type='submit' disabled={WordCount() < 5} className='btn-primary-new'>
              {t('Submit')}
            </button>
          </Spin>
        </form>
      </div>

      <div className='search-input-info d-flex justify-content-between mt-2'>
        {WordCount() > 0 && WordCount() < 5 && <span className='word-count'>{t('Please type at least 5 words')}</span>}
        <div className='char-count'>
          <span>{t('Max 50 words')}</span>
          <span>
            ({WordCount()}/50 {t('words left')})
          </span>
        </div>
      </div>

      {/* recent searches */}
      {recentSearchedData.length > 0 && (
        <>
          <div className='recent-searches'>
            <div className='rs-title'>{t('Recently asked')}</div>
            {recentSearchedData.map((item, idx) => {
              return showAllSearchedData ? (
                <div className='rs-data' key={idx} onClick={() => setInputVal(item.query)}>
                  <AiRecentlyIcon /> {item.query}
                </div>
              ) : (
                idx < 5 && (
                  <div className='rs-data' key={idx} onClick={() => setInputVal(item.query)}>
                    <AiRecentlyIcon /> {item.query}
                  </div>
                )
              )
            })}
          </div>
          {recentSearchedData.length > 5 && (
            <div className='see-all' onClick={() => setShowAllSearchedData(!showAllSearchedData)}>
              {showAllSearchedData ? t('See less') : t('See all')}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default AiModalStep1
