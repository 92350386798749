import * as Types from '../actions/type'

const balanceSheetReducer = (state = null, action) => {
  switch (action.type) {
    case Types.LOAD_BALANCE_SHEET:
      return action.payload
    default:
      return state
  }
}

export default balanceSheetReducer
