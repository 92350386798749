import nProgress from 'nprogress'
import http from '../../services/httpService'
import * as Types from './type'

// export const addApiAccount = () => ({
//      type: Types.ADD_APIACCOUNT
// })

export const getAllApiAccount = () => dispatch => {
  nProgress.start()
  http
    .get('/api-account')
    .then(function (response) {
      dispatch({
        type: Types.LOAD_APIACCOUNT,
        apiAccount: response.data,
      })
    })
    .catch(function (error) {
      console.log(error)
    })
    .finally(() => {
      nProgress.done()
    })
}

export const getApiAccount = id => dispatch => {
  nProgress.start()
  http
    .get('/api-account', id)
    .then(response => {})
    .catch(error => {
      console.log(error)
    })
    .finally(() => {
      nProgress.done()
    })
}

export const AddApiAccount = () => dispatch => {
  nProgress.start()
  http
    .post('/api-account')
    .then(response => {
      dispatch({
        type: Types.ADD_APIACCOUNT,
        payload: {
          apiAccount: response.data,
        },
      })
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => {
      nProgress.done()
    })
}

export const updateApiAccount = () => dispatch => {
  nProgress.start()
  http
    .put('/api-account')
    .then(response => {
      dispatch({
        type: Types.UPDATE_APIACCOUNT,
        payload: {
          apiAccount: response.data,
        },
      })
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => {
      nProgress.done()
    })
}

export const deleteApiAccount = () => dispatch => {
  nProgress.start()
  http
    .delete('/api-account')
    .then(response => {
      dispatch({
        type: Types.DELETE_APIACCOUNT,
        payload: {
          id: response.data._id,
        },
      })
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => {
      nProgress.done()
    })
}
