import { Popover, Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import SyncBoardIcon from '../images/svg/syncBoard'
import { filteredByModules, getShortMonthNameWithDate } from '../util/functions'
import SyncDropdwon from './SyncDropdwon'
import ShareBoard from './dashboard/ShareBoard'
import { syncBoardProgress } from '../store/actions/syncBoardAction'
import SyncModal from './dashboard/SyncModal'
import { customNotification } from '../util/customNotification'
import { isArray } from 'lodash'

let eventSource = null

const TopbarMenu = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const [showSync, setShowSync] = useState(false)
  const [showSyncOverwriteBySSE, setShowSyncOverwriteBySSE] = useState(true) // related with showSync
  const [currentSyncBoardId, setCurrentSyncBoardId] = useState('')
  const [showSyncModal, setShowSyncModal] = useState(false)
  const { user } = useSelector(state => state.auth)
  const { menu } = useSelector(state => state.auth.accessControl)
  const { apiGroup } = useSelector(state => state.main)
  const apiAccount = useSelector(state => state.apiAccount)
  const groupAccount = useSelector(state => state.groupAccount)
  const [currentGroup, setCurrentGroup] = useState([])
  const [currentGroupApis, setCurrentGroupApis] = useState([])
  const [syncDatum, setSyncDatum] = useState([])
  const [lastSyncedAt, setLastSyncedAt] = useState(null)
  const [currentSyncData, setCurrentSyncData] = useState([])
  const prevApiGroupRef = useRef(apiGroup)
  const dispatch = useDispatch()

  // Sync Permissions
  const syncPermission = filteredByModules(menu, 'SyncBoard')
  const sPermissions = syncPermission?.permissions

  useEffect(() => {
    if (apiGroup !== prevApiGroupRef.current) setLastSyncedAt(null)

    const cGroup = groupAccount?.filter(group => group._id === apiGroup) || []

    if (cGroup && isArray(cGroup)) {
      setCurrentGroupApis(apiAccount.filter(api => cGroup[0]?.apis.includes(api._id)))

      // eslint-disable-next-line no-unused-expressions
      cGroup[0]?.apis.forEach(api => {
        const acc = apiAccount.find(item => item._id === api)
        if (acc) {
          if (acc.syncInfo) {
            Object.keys(acc.syncInfo).forEach(key => {
              if (acc.syncInfo.hasOwnProperty(key) && acc.syncInfo[key].lastSyncedAt) {
                if (
                  !lastSyncedAt ||
                  new Date(acc.syncInfo[key].lastSyncedAt).getTime() > new Date(lastSyncedAt).getTime()
                ) {
                  setLastSyncedAt(acc.syncInfo[key].lastSyncedAt)
                }
              }
            })
          }
        }
      })
    }

    prevApiGroupRef.current = apiGroup
    setCurrentGroup(cGroup)
    setShowSyncOverwriteBySSE(true)
  }, [apiGroup, groupAccount, apiAccount, lastSyncedAt])

  const getSyncBoardProgressByApiGroups = async () => {
    const apiIds = apiAccount.map(item => `&apiIds[]=${item._id}`)
    const searchParams = apiIds.join('')

    const auth_token = localStorage.getItem('auth_token')
    eventSource = new EventSource(
      `${process.env.REACT_APP_API_URL}/sync-board/progress?token=${auth_token}${searchParams}`
    )
    eventSource.onmessage = e => {
      const data = JSON.parse(e.data)
      setSyncDatum(data)
      setShowSyncOverwriteBySSE(true)
    }
  }

  const syncData = async () => {
    // this will enable immediate show sync & prevent turn off sync by old SSE data
    setShowSyncOverwriteBySSE(false)
    setShowSync(true)

    dispatch(syncBoardProgress(apiGroup))
    setCurrentSyncBoardId(apiGroup)
  }

  useEffect(() => {
    if (syncDatum.length > 0 && currentGroup.length > 0) {
      const filteredData = syncDatum
        .map(item => {
          if (currentGroup[0].apis.includes(item._id)) {
            return item
          }
        })
        .filter(Boolean)

      let isSyncing = false

      filteredData.forEach(item => {
        if (!isSyncing && !item.apiKeyIsInvalid && item.syncInfo) {
          Object.keys(item.syncInfo).forEach(key => {
            if (item.syncInfo.hasOwnProperty(key) && item.syncInfo[key].percentage < 100) {
              isSyncing = true
            }
          })
        }
      })

      if (!isSyncing && showSyncOverwriteBySSE) {
        if (currentSyncBoardId === currentGroup[0]._id) {
          setCurrentSyncBoardId('')

          customNotification('success', 'Sync is completed')
        }
        setShowSync(false)
      } else {
        setShowSync(true)
        setCurrentSyncData(filteredData)
      }
    }
  }, [syncDatum, currentGroup, currentSyncBoardId])

  useEffect(() => {
    if (apiAccount.length > 0) {
      if (eventSource) eventSource.close()

      getSyncBoardProgressByApiGroups().then()
    }
  }, [apiAccount])

  return (
    <React.Fragment>
      {location.pathname.includes('admin') ? null : (
        <>
          {sPermissions?.includes('Sync') || sPermissions?.includes('*') ? (
            <>
              {user.email === 'susmita@getonnet.agency' && (
                <button className={'btn-secondary-outline px-3'} style={{ marginRight: '20px' }}>
                  <a href='/tripletex-test'>{t('Tripletex test')}</a>
                </button>
              )}
              {currentGroup[0]?.apis.length && currentGroupApis.filter(api => !api.apiKeyIsInvalid).length ? (
                showSync ? (
                  <Popover placement='bottomLeft' content={<SyncDropdwon data={currentSyncData} />}>
                    <button className={'btn-secondary-outline px-3 rotate-sync-icon-wrap'}>
                      <span className='rotate-sync-icon'>
                        <SyncBoardIcon />
                      </span>
                    </button>
                  </Popover>
                ) : (
                  <button
                    className={'btn-secondary-outline px-3'}
                    onClick={() => setShowSyncModal(true)}
                    title={`${t('Last synced on')} ${
                      lastSyncedAt ? getShortMonthNameWithDate(lastSyncedAt) : t('never')
                    }`}>
                    <SyncBoardIcon />
                  </button>
                )
              ) : (
                <Tooltip title={t('Please add API to your current board')} placement='top'>
                  <button
                    disabled={true}
                    className={'btn-secondary-outline px-3'}
                    style={{ opacity: 0.3, border: 'none', padding: 'none' }}>
                    <SyncBoardIcon />
                  </button>
                </Tooltip>
              )}
            </>
          ) : null}
          <ShareBoard />
          <SyncModal
            show={showSyncModal}
            onHide={() => setShowSyncModal(false)}
            lastSynced={lastSyncedAt}
            syncData={syncData}
          />
        </>
      )}
    </React.Fragment>
  )
}

export default TopbarMenu
