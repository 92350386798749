import * as React from 'react'
const AiMagicIcon = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width={21} height={22} fill='none' {...props}>
    <circle cx={10.5} cy={11} r={10} fill='#fff' stroke='#2D2D2D' strokeOpacity={0.1} />
    <g fill='#F76552' clipPath='url(#a)'>
      <path d='m10.038 12.932.293-.67a2.58 2.58 0 0 1 1.313-1.33l.805-.358a.33.33 0 0 0 0-.6l-.78-.346a2.582 2.582 0 0 1-1.332-1.375l-.296-.714a.315.315 0 0 0-.587 0l-.296.714a2.582 2.582 0 0 1-1.332 1.375l-.78.346a.33.33 0 0 0 0 .6l.805.357c.584.26 1.053.735 1.313 1.33l.293.67a.315.315 0 0 0 .581 0Z' />
      <path
        d='m12.967 14.563.082-.188c.147-.336.411-.604.74-.75l.254-.113a.177.177 0 0 0 0-.321l-.239-.107a1.456 1.456 0 0 1-.751-.775l-.085-.204a.169.169 0 0 0-.314 0l-.084.204a1.456 1.456 0 0 1-.751.775l-.24.107a.177.177 0 0 0 0 .32l.254.113c.33.147.594.415.74.75l.083.19c.06.137.25.137.311 0Z'
        opacity={0.4}
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M6.5 7h8v8h-8z' />
      </clipPath>
    </defs>
  </svg>
)
export default AiMagicIcon
