import React from 'react'
import { useTranslation } from 'react-i18next'
import SearchIcon from '../../../images/svg/search'

const SearchAccount = ({ setFunc, search, onSubmit }) => {
    const { t } = useTranslation()
    const handleSearch = event => {
        const query = event.target.value
        setFunc(query)
    }

    const handleKeyDown = event => {
        if (event.key === 'Enter' && onSubmit) {
            onSubmit()
        }
    }

    return (
        <div className="search-wrap d-flex align-items-centers">
            <input className="search-account-input w-100" placeholder={t(search)} type="text" name="search" onChange={handleSearch} onKeyDown={handleKeyDown} />
            <button className="search-btn" onClick={e => {e.preventDefault(); onSubmit && onSubmit()}}>
                <SearchIcon />
            </button>
        </div>
    )
}

export default SearchAccount
