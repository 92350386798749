import * as React from 'react'
const AISliderIcon = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width={32} height={32} fill='none' {...props}>
    <circle cx={16} cy={16} r={16} fill='#FFF7F7' />
    <circle cx={16} cy={16} r={15.5} stroke='#F76552' strokeOpacity={0.5} />
    <g clipPath='url(#a)'>
      <path fill='#F76552' d='m16.834 16-3.52-3.52 1.005-1.005L18.844 16l-4.525 4.526-1.006-1.006 3.52-3.52Z' />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M7.467 7.467h17.067v17.067H7.467z' />
      </clipPath>
    </defs>
  </svg>
)
export default AISliderIcon
