import * as React from 'react'
const AiEditIcon = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width={22} height={22} fill='none' {...props}>
    <path
      fill='#F76552'
      d='m1.667 13.667-.53-.53-.22.219v.31h.75Zm12-12 .53-.53a.75.75 0 0 0-1.06 0l.53.53Zm6.666 6.666.53.53a.75.75 0 0 0 0-1.06l-.53.53Zm-12 12v.75h.311l.22-.22-.53-.53Zm-6.666 0h-.75c0 .415.335.75.75.75v-.75Zm.53-6.136 12-12-1.06-1.06-12 12 1.06 1.06Zm10.94-12 6.666 6.667 1.06-1.061-6.666-6.667-1.06 1.061Zm6.666 5.606-12 12 1.06 1.06 12-12-1.06-1.06Zm-11.47 11.78H1.667v1.5h6.666v-1.5Zm-5.916.75v-6.666h-1.5v6.666h1.5Zm6.72-14.136 6.666 6.667 1.06-1.061-6.666-6.667-1.06 1.061Zm2.53 14.886H21v-1.5h-9.333v1.5Z'
    />
  </svg>
)
export default AiEditIcon
