import { RemovePublicBoardId } from './publicBoardAction'
import * as Types from './type'

export const StartDateAction = data => dispatch => {
  dispatch({
    type: Types.StartDate_CHANGE,
    payload: data,
  })
}

export const EndDateAction = data => dispatch => {
  dispatch({
    type: Types.EndDate_CHANGE,
    payload: data,
  })
}

export const WidgetStartDateAction = data => dispatch => {
  dispatch({
    type: Types.W_StartDate_CHANGE,
    payload: data,
  })
}

export const WidgetEndDateAction = data => dispatch => {
  dispatch({
    type: Types.W_EndDate_CHANGE,
    payload: data,
  })
}

export const ApiGroupAction = data => dispatch => {
  dispatch({
    type: Types.APIGROUP_CHANGE,
    payload: data,
  })
  dispatch(RemovePublicBoardId())
}

export const ApiGroupDelete = () => dispatch => {
  dispatch({
    type: Types.LAST_APIGROUP_DELETE,
  })
}

export const UpdateLocale = data => dispatch => {
  dispatch({
    type: Types.UPDATE_LOCALE,
    payload: data,
  })
}

export const ShowWelcomeVideo = data => dispatch => {
  dispatch({
    type: Types.SHOW_WELCOME_VIDEO,
    payload: data,
  })
}

export const HandleWidgetDrag = data => dispatch => {
  dispatch({
    type: Types.IS_DRAGGABLE_WIDGET,
    payload: data,
  })
}
