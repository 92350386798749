import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'antd'
import { useDispatch } from 'react-redux'
import { DeleteApiGroup } from '../../store/actions/apiGroupAction'

const DeleteBoardModal = ({ show, onHide, id }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const deleteBoard = () => {
    dispatch(DeleteApiGroup(id))
    onHide()
  }

  return (
    <Modal className='create-new-board-modal' title={t('Delete')} visible={show} onCancel={onHide} footer={null} >
      <div className="cnbm-delete-comfirm">
        <div>{t('Delete this board')}</div>
        <div className="delete-btn-wrap">
          <button className='btn-secondary' onClick={() => onHide()}>{t('Cancel')}</button>
          <button className='btn-outline-light' onClick={() => deleteBoard()}>
            <span>
              <span>{t('Delete')}</span>
            </span>
          </button>
        </div>
      </div>
    </Modal>

  )
}

export default DeleteBoardModal
