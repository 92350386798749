import * as Types from '../actions/type'

const apiAccount = []

const apiAccountReducer = (state = apiAccount, action) => {
  switch (action.type) {
    case Types.LOAD_APIACCOUNT:
      return [...action.apiAccount]
    case Types.ADD_APIACCOUNT:
      return [...state, ...action.apiAccount]
    case Types.DELETE_APIACCOUNT:
      return state
    case Types.UPDATE_APIACCOUNT:
      return state
    default:
      return state
  }
}

export default apiAccountReducer
