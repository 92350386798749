import { Modal } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AiBackArrowIcon from '../../images/svg/AiBackArrowIcon'
import AiModalStep1 from './AiModalStep1'
import AiModalStep2 from './AiModalStep2'

const AiModal = ({ show, onHide }) => {
    const { t } = useTranslation()
    const [step, setStep] = useState(1)
    const [ques, setQues] = useState('')
    const [result, setResult] = useState({})

    const onCancel = () => {
        setStep(1)
        setQues('')
        setResult({})
        onHide()
    }
    const getTitle = () => {
        return step === 2 ? <div> <span style={{ position: 'absolute', top: '12px', left: '14px', cursor: 'pointer' }} onClick={() => setStep(1)}><AiBackArrowIcon /></span>  {t('Reportify AI Assistant')}</div> : t('Reportify AI Assistant')
    }

    return (
        <Modal
            className='ai-modal'
            title={getTitle()}
            open={show}
            onCancel={onCancel}
            footer={null}
            centered={true}
            width='700px'
            direction={'rtl'}>
            <>
                {
                    step === 1 ? <AiModalStep1 setStep={setStep} setQues={setQues} ques={ques} setResult={setResult} />
                        : <AiModalStep2 setStep={setStep} setQues={setQues} result={result} onClose={onCancel} />
                }
            </>
        </Modal>
    )
}

export default AiModal