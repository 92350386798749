import React from 'react'
import { useTranslation } from 'react-i18next'

const Error = ({ error }) => {
    const { t } = useTranslation()

    return (
        <div className='error-screen'>
            <h2>{t('An error has occured')}</h2>
            <h4>{error.message}</h4>
            <h4> <a href="/login">
                {t('Click here to login again')}
            </a> </h4>
        </div>
    )
}

export default Error
