import { Progress } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SyncTickIcon from '../images/svg/SyncTickIcon'

const SyncDropdwon = ({ data }) => {
  const { t } = useTranslation()

  const [percentage, setPercentage] = useState([])

  useEffect(() => {
    if (data.length) {
      const pArray = []
      data.forEach(d => {
        let p = 0

        const { syncInfo } = d
        Object.keys(syncInfo).forEach(key => {
          if (key !== '_id') {
            p += syncInfo[key].percentage
          }
        })

        pArray.push({ ...d, percentage: Math.ceil(p / Object.keys(syncInfo).filter(k => k !== '_id').length) })
      })

      setPercentage(pArray)
    }
  }, [data])

  return (
    <div className='sync-dropdown'>
      <div>{t('Please wait while we update the changes')}</div>
      {percentage?.map(item => {
        return (
          <div className='sync-progressbar' key={item._id}>
            <div className='api-name'>{item.name}</div>
            {item.percentage === 100 ? (
              <div className='sync-complete-noti'>
                <SyncTickIcon /> <span>{t('Sync was successful')}</span>
              </div>
            ) : (
              <Progress
                strokeColor='#33D067'
                trailColor='#E0F8E8'
                percent={item.percentage}
                status='active'
                size={[100, 40]}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default SyncDropdwon
