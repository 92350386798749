import i18n from 'i18next'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { initReactI18next } from 'react-i18next'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import CoolLoader from './components/coolLoader'
import common_en from './locales/en/translation.json'
import common_nor from './locales/nor/translation.json'
import Routes from './routes'
// global styles for all pages
import './scss/style.scss'
import { persistor, store } from './store'
import { ErrorBoundary } from './util/errBoundary'
// setup logrocket
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID)
setupLogRocketReact(LogRocket)

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: common_en,
      },
      nor: {
        translation: common_nor,
      },
    },
    lng: window.localStorage.getItem('i18nextLng') || 'nor',
    fallbackLng: 'nor',

    interpolation: {
      escapeValue: false,
    },
  })

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense
          fallback={
            <div className='loader-wrap'>
              <CoolLoader message={'Loading'} />
            </div>
          }>
          <ErrorBoundary>
            <Routes />
          </ErrorBoundary>
        </Suspense>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
