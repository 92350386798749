import * as React from 'react'

function DeleteIcon(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <rect
        x={0.914}
        y={2.308}
        width={1.9}
        height={18}
        rx={0.95}
        transform='rotate(-45 .914 2.308)'
        fill='#2D2D2D'
      />
      <rect
        x={13.728}
        y={1}
        width={1.9}
        height={18}
        rx={0.95}
        transform='rotate(45 13.728 1)'
        fill='#2D2D2D'
      />
    </svg>
  )
}

export default DeleteIcon;
