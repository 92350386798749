import http from '../../services/httpService'
import * as Types from './type'

export const syncBoardProgress = id => dispatch => {
  http
    .post('/sync-board', {
      groupId: id,
    })
    // .then(function (response) {
    //   dispatch({
    //     type: Types.BOARD_SYNC,
    //     syncData: response,
    //   })
    // })
    .catch(function (error) {
      console.log(error)
    })
}

export const showProgress = () => dispatch => {
  dispatch({
    type: Types.SHOW_PROGRESS,
  })
}

export const hideProgress = () => dispatch => {
  setTimeout(() => {
    dispatch({
      type: Types.HIDE_PROGRESS,
    })
  }, 2000)
}

export const progressData = progress => dispatch => {
  dispatch({
    type: Types.BOARD_SYNC,
    payload: progress,
  })
}
