import React from 'react'
import Error from '../components/Error'

export default function Catch(component, errorHandler) {
  return class extends React.Component {
    state = {
      error: undefined,
    }

    static getDerivedStateFromError(error) {
      return { error }
    }

    componentDidCatch(error, info) {
      if (errorHandler) {
        errorHandler(error, info)
      }
    }

    render() {
      return component(this.props, this.state.error)
    }
  }
}

export const ErrorBoundary = Catch((props, error) => {
  if (error) {
    return <Error error={error} />
  } else {
    return <React.Fragment>{props.children}</React.Fragment>
  }
})
