import * as React from 'react'

function ArrowDownIcon(props) {
  return (
    <svg
      width={10}
      height={6}
      viewBox='0 0 10 6'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path opacity={0.4} d='M5 6l5-6H0l5 6z' fill='#2D2D2D' />
    </svg>
  )
}

export default ArrowDownIcon
