import { notification } from 'antd'
import i18n from 'i18next'

const getNotificationStyle = type => {
  return {
    success: {
      color: 'rgba(0, 0, 0, 0.65)',
      border: '1px solid #b7eb8f',
      borderRadius: '6px',
      backgroundColor: '#f6ffed',
    },
    warning: {
      color: 'rgba(0, 0, 0, 0.65)',
      border: '1px solid #ffe58f',
      borderRadius: '6px',
      backgroundColor: '#fffbe6',
    },
    error: {
      color: 'rgba(0, 0, 0, 0.65)',
      border: '1px solid #ffa39e',
      borderRadius: '6px',
      backgroundColor: '#fff1f0',
    },
    info: {
      color: 'rgba(0, 0, 0, 0.65)',
      border: '1px solid #91d5ff',
      borderRadius: '6px',
      backgroundColor: '#e6f7ff',
    },
  }[type]
}

export const customNotification = (type, msg) => {
  notification[type]({
    message: i18n.t(msg),
    style: getNotificationStyle(type),
    duration: 3,
  })
}
