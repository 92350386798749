import { combineReducers } from 'redux'
import authReducer from './authReducer'
import apiAccountReducer from './apiaccountReducer'
import userReducer from './userReducer'
import profitReducer from './profitReducer'
import cashflowReducer from './cashflowReducer'
import apiGroupAccountReducer from './apigroupReducer'
import mainReducer from './mainReducer'
import syncBoardReducer from './syncBoardReducer'
import consolidationReducer from './consolidationReducer'
import widgetsReducer from './widgetsReducer'
import roleReducer from './roleReducer'
import publicBoardReducer from './publicBoardReducer'
import balanceSheetReducer from './balanceSheetReducer'
import invitationReducer from './invitationReducer'
import adminReducer from './adminReducer'
import subBoardWidgetReducer from './subBoardWidgetReducer'

const appReducer = combineReducers({
  auth: authReducer,
  apiAccount: apiAccountReducer,
  user: userReducer,
  profitLoss: profitReducer,
  cashflow: cashflowReducer,
  balance: balanceSheetReducer,
  groupAccount: apiGroupAccountReducer,
  main: mainReducer,
  syncBoard: syncBoardReducer,
  consolidation: consolidationReducer,
  widgets: widgetsReducer,
  roles: roleReducer,
  invitations: invitationReducer,
  public: publicBoardReducer,
  admin: adminReducer,
  subBoardWidget: subBoardWidgetReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer
