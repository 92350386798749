import * as React from 'react'

const Logo = props => (
  <svg width={34} height={35} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#a)'>
      <path
        d='M17 34c-3.263 0-6.167-.718-8.745-2.186-2.577-1.468-4.6-3.459-6.069-6.037C.718 23.2 0 20.296 0 17.065c0-3.328.718-6.265 2.186-8.842 1.469-2.578 3.492-4.568 6.07-6.037C10.832.718 13.736 0 17 0c3.296 0 6.2.718 8.777 2.186 2.546 1.469 4.569 3.459 6.037 6.037C33.282 10.8 34 13.737 34 17.065c0 3.23-.718 6.135-2.186 8.712-1.468 2.578-3.491 4.569-6.037 6.037C23.2 33.249 20.296 34 17 34Z'
        fill='url(#b)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.888 34.261a14.417 14.417 0 0 1-3.752-1.534V20.981h3.752v9.43M18.697 34.555c-.718.098-1.469.13-2.187.13-.457 0-.946-.065-1.403-.13V16.51h3.59V30.77M24.537 32.727a12.845 12.845 0 0 1-3.426 1.534V11.779h3.426v16.544'
        fill='#fff'
      />
    </g>
    <defs>
      <linearGradient id='b' x1={24.824} y1={-0.123} x2={11.341} y2={33.861} gradientUnits='userSpaceOnUse'>
        <stop stopColor='#F64642' />
        <stop offset={1} stopColor='#DD2476' />
      </linearGradient>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h34v34.791H0z' />
      </clipPath>
    </defs>
  </svg>
)

export default Logo
