import * as Types from '../actions/type'

const roles = []

const roleReducer = (state = roles, action) => {
  switch (action.type) {
    case Types.FETCH_ALL_ROLES:
      return [...action.payload]
    default:
      return state
  }
}

export default roleReducer
