import * as React from 'react'

const ConsolidationDeleteIcon = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} fill='none' {...props}>
    <path
      stroke='#BCC4D0'
      d='M1 3.8h1.556m0 0H15m-12.444 0v9.8c0 .371.163.727.455.99.292.262.688.41 1.1.41h7.778c.412 0 .808-.148 1.1-.41.292-.263.455-.619.455-.99V3.8H2.556Zm2.333 0V2.4c0-.371.164-.727.455-.99.292-.262.688-.41 1.1-.41h3.112c.412 0 .808.147 1.1.41.291.263.455.619.455.99v1.4M6.444 7.3v4.2m3.112-4.2v4.2'
    />
  </svg>
)

export default ConsolidationDeleteIcon
