import * as Types from '../actions/type'

const init = {
  public_board_id: '',
  public_widgets: [],
  public_pages: [],
  groupId: '',
  name: '',
}

const publicBoardReducer = (state = init, action) => {
  switch (action.type) {
    case Types.SET_PUBLIC_BOARD_ID:
      return {
        ...state,
        public_board_id: action.payload,
      }
    case Types.REMOVE_PUBLIC_BOARD_ID:
      return {
        ...state,
        public_board_id: '',
      }
    case Types.FETCH_PUBLIC_BOARD_DATA:
      return {
        ...state,
        public_widgets: action.payload?.widgets,
        public_pages: action.payload?.pages,
        groupId: action.payload?.group?._id,
        name: action.payload?.group?.name,
        lang: action.payload?.lang,
      }
    default:
      return state
  }
}

export default publicBoardReducer
