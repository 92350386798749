import * as Types from './type'
import nProgress from 'nprogress'
import http from '../../services/httpService'
import { customNotification } from '../../util/customNotification'
import { LoadCustomWidget } from './widgetsAction'

export const fetchAllSubBoardWidgetAction = groupId => dispatch => {
  nProgress.start()
  http
    .get(`/dashboard-folders/${groupId}`)
    .then(response => {
      window.localStorage.setItem('subBoardId', response.data[0]._id)
      dispatch({ type: Types.FETCH_ALL_SUBBOARD_WIDGET, payload: response.data })
    })
    .catch(error => {
      dispatch({ type: Types.FETCH_ALL_SUBBOARD_WIDGET, payload: [] })
      // customNotification('error', 'Subboard Widget Failed to Update')
    })
    .finally(() => {
      nProgress.done()
    })
}

//Update widgets in a single groupAccount
export const UpdateSubBoardWidgets = (data, groupId, subBoardId, actionType, customWidgetId) => dispatch => {
  nProgress.start()
  http
    .put(`/dashboard-folders/${groupId}/${subBoardId}`, { widgets: data })
    .then(response => {
      dispatch(fetchAllSubBoardWidgetAction(groupId))
      if (customWidgetId) {
        dispatch(LoadCustomWidget(customWidgetId))
      }
      if (response.status === 200) {
        let msg = ''
        let msgType = ''
        switch (actionType) {
          case 'widget-add':
            msg = 'Widget added successfully'
            msgType = 'success'
            break
          case 'widget-update':
            msg = 'Board Updated Successfully'
            msgType = 'success'
            break
          case 'custom-widget-update':
            msg = 'Custom widget updated successfully'
            msgType = 'success'
            break
          case 'delete':
            msg = 'Widget deleted successfully'
            msgType = 'success'
            break
          case 'view':
            msg = 'Widget view changed Successfully'
            msgType = 'success'
            break
          default:
            msg = 'Board Updated Successfully'
            msgType = 'success'
            break
        }
        customNotification(msgType, msg)
      }
    })
    .catch(error => {
      console.log(error)
      customNotification('error', 'Board Failed to Update')
    })
    .finally(() => {
      nProgress.done()
    })
}
