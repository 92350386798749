import { Checkbox, Dropdown, Input, Select, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import ShareIcon from '../../images/svg/sharelink'
import { CreatePublicBoard, RemovePublicBoardId, SendPublicBoardLinkViaEmail } from '../../store/actions/publicBoardAction'
import { customNotification } from '../../util/customNotification'

const ShareBoard = () => {
    const { t } = useTranslation()
    const [widgets, setWidgets] = useState([])
    const [pages, setPages] = useState([])
    const [showShareOption, setShowShareOption] = useState(false)
    const [boardName, setBoardName] = useState('')
    const [publicUrl, setPublicUrl] = useState('')
    const [loading, setLoading] = useState(false)
    const [copy, setCopy] = useState(false)
    const [emails, setEmails] = useState([])
    const [email, setEmail] = useState('')
    const [errors, setErrors] = useState([])
    const { Option } = Select;
    const groupAccount = useSelector(state => state.groupAccount)
    const { apiGroup } = useSelector(state => state.main)
    const apiAccount = useSelector(state => state.apiAccount)
    const { public_board_id: publicBoardId } = useSelector(state => state.public)
    const { activeLocale } = useSelector(state => state.main)
    const [lang, setLang] = useState('NOR')

    const dispatch = useDispatch()

    // Get current group
    const currentGroup = groupAccount?.filter((group) => group._id === apiGroup)
    const widgetsArray = currentGroup[0]?.widgets

    useEffect(() => {
        setLang(activeLocale.toUpperCase())
    }, [activeLocale])

    useEffect(() => {
        if (currentGroup) {
            setBoardName(currentGroup[0]?.name)
        }
    }, [currentGroup])

    const widgetList = [];
    if (widgetsArray?.length > 0) {
        widgetsArray.forEach(item => {
            widgetList.push(<Option key={item.id} value={item.id}>{item.name}</Option>);
        });
    }

    const handleGroupAdd = (value) => {
        setWidgets(value)
    }

    const handleSubmit = (checked, e) => {
        if (checked && (widgets.length > 0 || pages.length > 0)) {
            // let modifiedWidgetArray = widgets.map((widgetId, idx) => ({ id: widgetId, order: idx + 1 }))
            let modifiedWidgetArray = widgets.map(item => {
                let item2 = widgetsArray.find(i2 => (i2.widget ? i2.widget : i2.id) === item)
                return item2 ? { ...item2 } : item
            })
            const payload = modifiedWidgetArray.map((item) => ({
                id: item.id,
                x: item.x,
                y: item.y,
                width: item.width,
                height: item.height,
                view: item.view,
            }))
            let finalPages = pages.map((page) => ({ name: page }))
            dispatch(CreatePublicBoard(payload, finalPages, apiGroup, lang))
            setLoading(true)
            // setWidgets([])

        } else {
            customNotification('error', 'All fields are required')
            return false;
        }
    }

    useEffect(() => {
        if (publicBoardId) {
            let URl = `${process.env.REACT_APP_PUBLIC_URL}/public/board/${publicBoardId}`
            setPublicUrl(URl)
            setCopy(false)
            setLoading(false)
        }
    }, [publicBoardId])

    // On Closing Dropdown
    const onClosingDropdown = val => {
        setShowShareOption(val)
        dispatch(RemovePublicBoardId())
        setPages([])
        setWidgets([])
    }

    const onPagesCheck = val => {
        setPages(val)
    }

    //dummy value
    const pagesOption = [
        { label: t('Cashflow'), value: 'PAGES.CASHFLOW' },
        { label: t('Profit Loss'), value: 'PAGES.PROFIT_LOSS' },
        { label: t('Balance Sheet'), value: 'PAGES.BALANCE_SHEET' },
    ]

    const addEmail = e => {
        const { value } = e.target
        setEmail(value)
    }

    const handleEmailAdd = () => {
        let errors = []

        //email
        const expression = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        let validEmail = expression.test(String(email).toLowerCase())

        if (!validEmail) {
            errors.push('email')
            setErrors(errors)
        }
        else {
            setEmails(prevState => [...prevState, email])
            setEmail('')
            setErrors([])
        }
    }

    const removeEmail = id => {
        setEmails(prevState => [...prevState.filter(item => item !== id)])
    }

    const sendLinks = () => {
        if (emails.length !== 0) {
            dispatch(SendPublicBoardLinkViaEmail(emails, publicUrl, apiGroup))
        } else {
            customNotification('error', 'All fields are required')
            return false;
        }
    }

    const handleLanguageChange = (value) => {
        setLang(value)
    }

    const languageType = [
        {
            label: 'Norwegian',
            value: 'NOR',
        },
        {
            label: 'English',
            value: 'EN',
        },
    ]

    const GetLanguage = ({ label, value, handleChange, selected }) => {
        return (
            <div className="custom-widget-type-wrap">
                <div className='radio-container' onClick={handleChange}>
                    <div className={selected === value ? 'selected' : 'not-selected'}></div>
                    <span>
                        {t(label)}
                    </span>
                </div>
            </div>
        )
    }

    const getShareOptions = () => (
        <div className="dropdown-container">
            <div className="dropdown-wrap">
                <div className="share-borad">
                    <h5 className="mb-4">{t('Share this board publicly')}</h5>
                    <div>{t("Group Name")}: <span style={{ fontWeight: 'bold' }}>{boardName}</span></div>
                    {/* Select multiple group accounts */}
                    <form onSubmit={handleSubmit}>

                        <div className="mt-4">{t('Select language')}</div>
                        <div className="widget-type-radio">
                            {languageType.map((item, idx) => (
                                <GetLanguage key={idx} label={item.label} value={item.value} handleChange={() => handleLanguageChange(item.value)} selected={lang} />
                            ))}
                        </div>

                        <div className="share-board-select search-groupAccount mt-4">
                            <Select
                                mode="multiple"
                                value={widgets}
                                placeholder={t('Select Widgets to share')}
                                allowClear
                                style={{ width: '100%' }}
                                onChange={handleGroupAdd}
                                className=""
                                showArrow={false}
                            >
                                {widgetList}
                            </Select>
                        </div>

                        <div className="mt-4">{t('Share Pages')}</div>
                        <div className="pages-checked">
                            <Checkbox.Group options={pagesOption} onChange={onPagesCheck} />
                        </div>

                        <div className='generate-link-wrap form-cta mt-4'>
                            <div>{t('Generate Link')}</div>
                            <Switch size="small" loading={loading} checked={!(widgets.length) && publicBoardId} onChange={handleSubmit} />
                        </div>

                    </form>
                    {publicBoardId ?
                        <div className="public-link-wrap">
                            <div className="mt-4"><ShareIcon />{t('Public Link')}</div>
                            <div className="public-board-link mt-4">
                                <Input value={publicUrl} />
                                <CopyToClipboard
                                    text={publicUrl}
                                    onCopy={() => setCopy(true)}
                                >
                                    <button
                                        className='btn btn-outline-primary'
                                    >{copy ? t('Copied') : t('Copy')}</button>
                                </CopyToClipboard>
                            </div>

                            <div className="show-or">
                                <span className="line"></span>
                                <span className="or">{t('OR')}</span>
                                <span className="line"></span>
                            </div>

                            <div className="mt-4"><ShareIcon />{t('Send link to email')}</div>
                            <div className='public-board-link mt-4 d-flex'>
                                <Input
                                    onChange={addEmail}
                                    value={email}
                                    placeholder={t('Email')}
                                />
                                <button className="btn btn-outline-primary" onClick={handleEmailAdd}>{t('Add')}</button>
                            </div>
                            {errors.includes('email') && <span className='text-danger'> {t('Enter a valid email')} </span>}
                            <div className="show-all-emails-wrap mt-4">
                                {emails.map((item) => (
                                    <div key={item} className="email">
                                        <span>{item}</span>
                                        <span style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => removeEmail(item)}>x</span>
                                    </div>
                                ))}
                            </div>
                            <button className="btn btn-primary mt-4 w-100" onClick={() => sendLinks()}>{t('Send the Link')}</button>

                        </div>
                        :
                        null
                    }
                </div>
            </div>
        </div>
    );

    return (
        <Dropdown
            overlay={getShareOptions()}
            trigger={["click"]}
            visible={showShareOption}
            onVisibleChange={val => onClosingDropdown(val)}
        >
            <div className="ml-3 mr-3">
                <button
                    className={
                        'btn-secondary-outline d-flex align-items-center ' + (groupAccount.length && apiAccount.length ? '' : 'disable-feature')
                    }
                >
                    <ShareIcon />
                    <span className='ml-2' style={{ whiteSpace: 'nowrap' }}>{t('Share Board')}</span>
                </button>
            </div>
        </Dropdown>
    )
}

export default ShareBoard
