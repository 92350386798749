export const LOAD_APIACCOUNT = 'LOAD_APIACCOUNT'
export const ADD_APIACCOUNT = 'ADD_APIACCOUNT'
export const UPDATE_APIACCOUNT = 'UPDATE_APIACCOUNT'
export const DELETE_APIACCOUNT = 'DELETE_APIACCOUNT'
export const SET_USER = 'SET_USER'
export const SET_MENU = 'SET_MENU'
export const USERS_ERROR = 'USERS_ERROR'
export const LOAD_CASHFLOW = 'LOAD_CASHFLOW'
export const LOAD_PROFIT = 'LOAD_PROFIT'
export const ADD_GROUPACCOUNT = 'ADD_GROUPACCOUNT'
export const DELETE_GROUPACCOUNT = 'DELETE_GROUPACCOUNT'
export const LOAD_GROUPACCOUNT = 'LOAD_GROUPACCOUNT'
export const StartDate_CHANGE = 'StartDate_CHANGE'
export const EndDate_CHANGE = 'EndDate_CHANGE'
export const W_StartDate_CHANGE = 'W_StartDate_CHANGE'
export const W_EndDate_CHANGE = 'W_EndDate_CHANGE'
export const APIGROUP_CHANGE = 'APIGROUP_CHANGE'
export const LOGOUT = 'LOGOUT'
export const BOARD_SYNC = 'BOARD_SYNC'
export const SHOW_PROGRESS = 'SHOW_PROGRESS'
export const HIDE_PROGRESS = 'HIDE_PROGRESS'
export const UPDATE_CONSOLIDATION = 'UPDATE_CONSOLIDATION'
export const LAST_APIGROUP_DELETE = 'LAST_APIGROUP_DELETE'
export const TOP_CUSTOMERS = 'TOP_CUSTOMERS'
export const TOP_SUPPLIERS = 'TOP_SUPPLIERS'
export const TOP_EXPENSES = 'TOP_EXPENSES'
export const TOP_10_COSTS = 'TOP_10_COSTS'
export const TOP_10_PRODUCTS = 'TOP_10_PRODUCTS'
export const CUSTOM_WIDGETS = 'CUSTOM_WIDGETS'
export const LARGEST_TRANSACTION = 'LARGEST_TRANSACTIONT'
export const TURNOVER_WIDGET = 'TURNOVER_WIDGETT'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const FETCH_ALL_ROLES = 'FETCH_ALL_ROLES'
export const FETCH_ALL_WIDGETS = 'FETCH_ALL_WIDGETS'
export const UPDATE_GROUPACCOUNT = 'UPDATE_GROUPACCOUNT'
export const SET_PUBLIC_BOARD_ID = 'SET_PUBLIC_BOARD_ID'
export const REMOVE_PUBLIC_BOARD_ID = 'REMOVE_PUBLIC_BOARD_ID'
export const FETCH_PUBLIC_BOARD_DATA = 'FETCH_PUBLIC_BOARD_DATA'
export const LOAD_BALANCE_SHEET = 'LOAD_BALANCE_SHEET'
export const FETCH_ALL_INVITATIONS = 'FETCH_ALL_INVITATIONS'
export const UPDATE_LOCALE = 'UPDATE_LOCALE'
export const SHOW_WELCOME_VIDEO = 'SHOW_WELCOME_VIDEO'
export const IS_DRAGGABLE_WIDGET = 'IS_DRAGGABLE_WIDGET'
export const GET_USERS_FOR_ADMIN = 'GET_USERS_FOR_ADMIN'
export const GET_USER_FOR_ADMIN = 'GET_USER_FOR_ADMIN'
export const GET_ADMIN_SUMMARY = 'GET_ADMIN_SUMMARY'
export const ACTIVATE_USER = 'ACTIVATE_USER'
export const FETCH_ALL_SUBBOARD_WIDGET = 'FETCH_ALL_SUBBOARD_WIDGET'
export const SET_SUB_BOARD_ID = 'SET_SUB_BOARD_ID'
export const MRR_WIDGET = 'MRR_WIDGET'
export const SYNC_BOARD_PROGRESS = 'SYNC_BOARD_PROGRESS'
export const AI_WIDGET = 'AI_WIDGET'
