import * as Types from '../actions/type'

const admin = {
  users: {},
  summary: {},
  user: {},
}

const adminReducer = (state = admin, action) => {
  switch (action.type) {
    case Types.GET_USERS_FOR_ADMIN:
      return {
        ...state,
        users: action.payload,
      }
    case Types.GET_USER_FOR_ADMIN:
      return {
        ...state,
        user: action.payload,
      }
    case Types.GET_ADMIN_SUMMARY:
      return {
        ...state,
        summary: action.payload,
      }
    case Types.ACTIVATE_USER:
      return {
        ...state,
        user: action.payload,
        // users: state.users?.map(user => {
        //   if (user.id === action.payload.id) {
        //     return { ...user, ...action.payload }
        //   }
        //   return user
        // }),
      }
    default:
      return state
  }
}

export default adminReducer
