import * as Types from '../actions/type'

const groupAccount = []

const apiGroupAccountReducer = (state = groupAccount, action) => {
  switch (action.type) {
    case Types.LOAD_GROUPACCOUNT:
      return action.payload.groupAccount
    case Types.ADD_GROUPACCOUNT:
      return [...state, action.payload.groupAccount]
    case Types.UPDATE_GROUPACCOUNT:
      return state.map(group => {
        if (group._id === action.payload.groupAccount?._id) {
          return { ...action.payload.groupAccount }
        }
        return group
      })
    case Types.DELETE_GROUPACCOUNT:
      return state.filter(item => item._id !== action.payload)

    default:
      return state
  }
}

export default apiGroupAccountReducer
