import * as Types from '../actions/type'

const widgets = {
  allWidgets: [],
  top5Expenses: { data: [], loading: false },
  top10Customers: { data: [], loading: false },
  top10Suppliers: { data: [], loading: false },
  top10Costs: { data: [], loading: false },
  top10Products: { data: [], loading: false },
  largestTransactions: { data: [], loading: false },
  mrr: { data: [], loading: false, prevMrr: 0 },
  customWidgets: [],
  aiWidgets: [],
  turnover: {},
}

const widgetsReducer = (state = widgets, action) => {
  switch (action.type) {
    case Types.FETCH_ALL_WIDGETS:
      return {
        ...state,
        allWidgets: action.payload,
      }
    case Types.TOP_CUSTOMERS:
      return {
        ...state,
        top10Customers: { data: action.payload, loading: action.loading },
      }
    case Types.TOP_SUPPLIERS:
      return {
        ...state,
        top10Suppliers: { data: action.payload, loading: action.loading },
      }
    case Types.TOP_EXPENSES:
      return {
        ...state,
        top5Expenses: { data: action.payload, loading: action.loading },
      }
    case Types.TOP_10_COSTS:
      return {
        ...state,
        top10Costs: { data: action.payload, loading: action.loading },
      }
    case Types.TOP_10_PRODUCTS:
      return {
        ...state,
        top10Products: { data: action.payload, loading: action.loading },
      }
    case Types.LARGEST_TRANSACTION:
      return {
        ...state,
        largestTransactions: { data: action.payload, loading: action.loading },
      }
    case Types.MRR_WIDGET:
      return {
        ...state,
        mrr: { data: action.payload, loading: action.loading, prevMrr: action.prev },
      }
    case Types.TURNOVER_WIDGET:
      return {
        ...state,
        turnover: action.payload,
      }
    case Types.CUSTOM_WIDGETS:
      return {
        ...state,
        customWidgets: [...state.customWidgets.filter(item => item.id !== action.payload.id), action.payload],
      }

    case Types.AI_WIDGET:
      return {
        ...state,
        aiWidgets: [...state.aiWidgets.filter(item => item.queryId !== action.payload.queryId), action.payload],
      }
    default:
      return state
  }
}

export default widgetsReducer
