import axios from 'axios'

// axios.defaults.baseURL = 'http://95.217.18.249:8085'
axios.defaults.baseURL = process.env.REACT_APP_API_URL
// axios.defaults.baseURL = 'http://localhost:8085'

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

const setAuthToken = token => (axios.defaults.headers.common['access-token'] = token)

const setTokenToLocalStorage = response => {
  localStorage.setItem('auth_token', response.data.jwtToken)
  localStorage.setItem('refresh_token', response.data.refreshToken)
}

let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach(promise => {
    if (error) {
      promise.reject(error)
    } else {
      promise.resolve(token)
    }
  })

  failedQueue = []
}

//axios request interceptor
axios.interceptors.request.use(
  config => {
    const accessToken = localStorage.getItem('auth_token')
    if (accessToken) {
      config.headers['access-token'] = accessToken
    } else {
      config.headers['access-token'] = ''
    }
    return config
  },
  async error => {
    return Promise.reject(error)
  }
)

//axios response interceptor for refresh token
axios.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    const originalRequest = error.config

    if (error.response.status === 401 && originalRequest.url.includes('/auth/refresh-token')) {
      window.location.href = '/login'
      return Promise.reject(error)
    }

    const refreshToken = localStorage.getItem('refresh_token')
    if (error.response.status === 401 && refreshToken && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject })
        })
          .then(token => {
            originalRequest.headers['access-token'] = token
            return axios(originalRequest)
          })
          .catch(err => {
            return Promise.reject(err)
          })
      }

      originalRequest._retry = true
      isRefreshing = true

      return new Promise((resolve, reject) => {
        axios
          .post('/auth/refresh-token', { refreshToken })
          .then(res => {
            setTokenToLocalStorage(res)
            const { jwtToken } = res.data
            axios.defaults.headers.common['access-token'] = jwtToken
            originalRequest.headers['access-token'] = jwtToken
            processQueue(null, jwtToken)
            resolve(axios(originalRequest))
          })
          .catch(error => {
            processQueue(error, null)
            reject(error)
          })
          .finally(() => {
            isRefreshing = false
          })
      })
    }
    return Promise.reject(error)
  }
)

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
  setAuthToken,
  setTokenToLocalStorage,
}
