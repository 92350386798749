import * as React from 'react'
const AiInfoIcon = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
    <path
      fill='#999FBF'
      d='m7.313 18.872-3.085 2.587A.75.75 0 0 1 3 20.887V6a.75.75 0 0 1 .75-.75h16.5A.75.75 0 0 1 21 6v12a.75.75 0 0 1-.75.75H7.66l-.348.122Z'
    />
    <path
      stroke='#999FBF'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='m7.313 18.872-3.085 2.587A.75.75 0 0 1 3 20.887V6a.75.75 0 0 1 .75-.75h16.5A.75.75 0 0 1 21 6v12a.75.75 0 0 1-.75.75H7.66l-.348.122Z'
    />
    <path fill='#fff' d='M11.25 7.5h1.5v5.25h-1.5zM11.25 14.25h1.5v1.5h-1.5z' />
  </svg>
)
export default AiInfoIcon
