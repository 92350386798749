export const convertDate = str => {
  let date = new Date(str),
    month = ('0' + (date.getMonth() + 1)).slice(-2)
  return [date.getFullYear(), month, '01'].join('/')
}

export const FirstDayOfYear = () => {
  return [new Date().getFullYear(), 1, '01'].join('/')
}

export const LastDayOfYear = () => {
  return [new Date().getFullYear(), 11, '31'].join('/')
}

export const previousMonth = str => {
  let date = new Date(str),
    month = ('0' + (date.getMonth() - 1)).slice(-2)
  return [date.getFullYear(), month, '01'].join('/')
}

export const convert = str => {
  let date = new Date(str),
    month = ('0' + (date.getMonth() + 1)).slice(-2),
    day = ('0' + date.getDate()).slice(-2)
  return [date.getFullYear(), month, day].join('/')
}

export const getLastDate = () => {
  const currentYear = new Date().getFullYear()
  const date = new Date(currentYear, 11, 31)
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  const day = ('0' + date.getDate()).slice(-2)
  return [date.getFullYear(), month, day].join('/')
}

export const dateReverse = str => {
  let date = new Date(str),
    month = ('0' + (date.getMonth() + 1)).slice(-2)
  return [date.getFullYear(), month, '01'].join('/')
}

const daysInMonth = (month, year) => {
  // Use 1 for January, 2 for February, etc.
  return new Date(year, month, 0).getDate()
}

export const reverseEndDate = str => {
  let date = new Date(str),
    month = ('0' + (date.getMonth() + 1)).slice(-2)
  return [date.getFullYear(), month, daysInMonth(month, date.getFullYear())].join('/')
}

export const newMonthFormat = str => {
  let date = new Date(str),
    month = ('0' + (date.getMonth() + 1)).slice(-2),
    day = ('0' + date.getDate()).slice(-2)
  return [month, day, date.getFullYear()].join('/')
}

export const revertDate = str => {
  let date = new Date(str),
    month = ('0' + (date.getMonth() + 1)).slice(-2),
    day = ('0' + date.getDate()).slice(-2)
  return [date.getFullYear(), month, day].join('/')
}

export const addDaysToDate = (date, days) => {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

export const getFirstLetterFromName = name => {
  if (name) {
    let firstLetters = name
      .split(' ')
      .map(i => i.charAt(0))
      .join()
      .toUpperCase()
    return firstLetters.substr(0, 1)
  }
}

/**
 * Get previous or next month at specified distance
 * @param date
 * @param months
 * @returns {*}
 */
export const addMonths = (date, months) => {
  date.setMonth(date.getMonth() + months)
  return date
}

/**
 * Month names
 * @type {string[]}
 */
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const shortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

/**
 * Get month name from month no or full date
 * @param monthNum
 * @returns {string}
 */
export const getMonthNameFromMonthNum = monthNum => monthNames[monthNum]
export const getMonthNameFromDate = date => monthNames[new Date(date).getMonth()]

export const getShortMonthNameFromDate = (date, uppercase = false) => {
  return uppercase
    ? shortMonthNames[new Date(date).getMonth()].toUpperCase()
    : shortMonthNames[new Date(date).getMonth()]
}

export const getShortMonthNameWithDate = date => {
  const month = getShortMonthNameFromDate(date)
  const day = `0${new Date(date).getDate()}`.slice(-2)

  return [day, month].join(' ')
}

export const getShortMonthNameWithYear = date => {
  const month = getShortMonthNameFromDate(date)
  const shortYear = new Date(date).toLocaleDateString('en', { year: '2-digit' })

  return [month, shortYear].join("'")
}

export const getFullMonthNameWithShortYear = date => {
  const month = getShortMonthNameFromDate(date)
  const shortYear = new Date(date).toLocaleDateString('en', { year: 'numeric' })

  return [month, shortYear].join(' ')
}

export const getFullMonthNameWithFullYear = date => {
  const month = getMonthNameFromDate(date)
  const year = new Date(date).toLocaleDateString('en', { year: 'numeric' })

  return [month, year].join(' ')
}

export const convertNum = num => Math.round(num).toLocaleString('en-US').replace(',', ' ')

/**
 * Format money numbers
 * Remove decimal points and add thousand separator
 * @param num
 * @returns {string}
 */
export const formatMoney = num => {
  if (num < 0 && num > -1) {
    num *= -1
  }
  if (!isNaN(num)) {
    return Math.round(num).toLocaleString('en-US').replace(',', ' ')
  } else {
    return num
  }
}

export const removeNegativeSign = num => {
  let newNum = parseInt(num.replace(/[, ]+/g, '') || '0', 10)
  if (newNum < 0 && newNum > -1) {
    newNum *= -1
  }
  return Math.round(newNum).toLocaleString('no-NO').replace(',', ' ')
}

export const StringToNumber = arr => {
  return arr.map(item => Number(item))
}

//Filtering menu for allowed paths
export const filteredRoutes = arr => {
  let routes = []
  if (arr?.length > 0) {
    routes = arr.filter(item => item.route).map(routeItem => routeItem.route)
  }
  return routes
}

export const filteredMenus = arr => {
  let menus = []
  if (arr?.length > 0) {
    menus = arr.filter(item => item.menuName).sort((a, b) => a.order - b.order)
  }
  return menus
}

export const filteredByModules = (arr, name) => {
  let module = {}
  if (arr?.length > 0) {
    module = arr.find(item => item.name === name)
  }
  return module
}

export const formatNumberValue = val => {
  return Math.floor(val)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const customWidgetValidate = value => {
  const expression = /^(?!0)(?!x|X)[0-9x|X]{2,}$/
  let validNumber = expression.test(value) && String(value).length === 4

  return validNumber
}

export const roundToTwoDecimal = num => {
  return +(Math.round(num + 'e+2') + 'e-2')
}

export const getSearchParams = () => {
  if (window.location.search) {
    return JSON.parse(
      '{"' +
        decodeURI(window.location.search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') +
        '"}'
    )
  } else return null
}

export const getCurrenntWidgets = (widgets, currentSubBoardId, apiGroupId) => {
  let currentWidgets = []
  const checkingWidget = widgets?.dashboardWidget?.find(
    widget => widget._id === currentSubBoardId && widget.groupId === apiGroupId
  )
  if (checkingWidget) {
    currentWidgets = checkingWidget.widgets
  }
  return currentWidgets
}

export function differenceInMonths(d1, d2) {
  const date2 = new Date(d1)
  const date1 = new Date(d2)
  const monthDiff = date1.getMonth() - date2.getMonth()
  const yearDiff = date1.getYear() - date2.getYear()

  return monthDiff + yearDiff * 12 + 1
}

export function getYearFromDate(date) {
  if (!date) return new Date().getFullYear()
  return [getShortMonthNameFromDate(date, true), new Date(date).getFullYear()].join(', ')
}

export const checkIfNumber = val => {
  return isNaN(val) ? 0 : val
}

export const mergeAllApiAccountData = obj => {
  if (Object.keys(obj).length > 0) {
    return Object.values(obj).reduce((a, item) => {
      if (a.mrr) {
        Object.keys(item).forEach(i => {
          if (typeof item[i] === 'object') {
            a[i] = [new Set(...[a[i], ...item[i]])]
          } else {
            a[i] += item[i]
          }
        })
      } else {
        return item
      }
      return a
    })
  }
}
