import React from 'react'

function SearchIcon(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={18}
      height={18}
      fill='none'
      viewBox='0 0 18 18'
      {...props}>
      <path
        stroke='#2D2D2D'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M17 17l-3.867-3.867m2.09-5.022A7.111 7.111 0 111 8.111a7.111 7.111 0 0114.222 0z'
      />
    </svg>
  )
}

export default SearchIcon
