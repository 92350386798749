import nProgress from 'nprogress'
import http from '../../services/httpService'
import { store } from '../index'
import * as Types from './type'

export const LoadCashflow = groupId => dispatch => {
  nProgress.start()
  let cashflowItem = {},
    cashFlowSubItems = {},
    allMonths = [],
    openingBalance = []

  const populate_sub_items = (subItem, count) => {
    if (cashFlowSubItems[subItem.group][count]) {
      cashFlowSubItems[subItem.group][count].push(subItem.amount)
    } else {
      cashFlowSubItems[subItem.group][count] = ['child']
      if (localStorage.getItem('i18nextLng') == 'en') {
        cashFlowSubItems[subItem.group][count].push(subItem.en)
      } else {
        cashFlowSubItems[subItem.group][count].push(subItem.no)
      }
      cashFlowSubItems[subItem.group][count].push(subItem.amount)
    }
  }

  http
    .get('/cashflow', {
      params: {
        groupId: groupId ? groupId : store.getState().main.apiGroup,
        startDate: store.getState().main.start_date,
        endDate: store.getState().main.end_date,
      },
      // For public board sharing
      headers: {
        'board-id': store.getState().public.public_board_id,
      },
    })
    .then(response => {
      // format data
      response.data.cashflows.forEach(monthData => {
        allMonths.push(monthData.month)
        monthData.groups.forEach(item => {
          if (cashflowItem[item.group] && cashflowItem[item.group].length) {
            cashflowItem[item.group].push(item.totalAmountOfGroup)
          } else {
            cashflowItem[item.group] = ['parent']
            if (localStorage.getItem('i18nextLng') == 'en') {
              cashflowItem[item.group].push(item.title.en)
            } else {
              cashflowItem[item.group].push(item.title.no)
            }
            cashflowItem[item.group].push(item.totalAmountOfGroup)
          }

          // handling subitems
          item.subItems.forEach((subitem, index) => {
            if (cashFlowSubItems[subitem.group]) {
              populate_sub_items(subitem, index)
            } else {
              cashFlowSubItems[subitem.group] = {}
              populate_sub_items(subitem, index)
            }
          })
        })
      })

      response.data.openings.forEach(opening => {
        openingBalance.push(opening.openingBalance)
      })

      dispatch({
        type: Types.LOAD_CASHFLOW,
        payload: {
          cashflow: {
            parent: cashflowItem,
            child: cashFlowSubItems,
            months: allMonths,
            opening: openingBalance,
          },
        },
      })
    })
    .finally(() => nProgress.done())
}
